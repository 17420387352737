import Appearances from 'utils/Appearances';
import * as types from './actionTypes';

const initialState = {
  avatarLoading: false,
  editsInProgress: false,
  body: 'male',
  category: undefined,
  // selection refers to a subcategory
  selection: undefined,
  // material refers to the texture of a subcategory
  material: undefined,
  // this is the "saved" look for each body type as the user is exploring the options.
  color: undefined,
  asdkId: '',
  looks: {
    male: {
      categories: [],
    },
    female: {
      categories: [],
    },
    boy: {
      categories: [],
    },
    girl: {
      categories: [],
    },
  },
  appearanceData: '',
  forceBroadcast: false,
  isFullBody: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_APPEARANCE_BODY: {
      const {body} = action.payload;

      return {
        ...state,
        body,
        category: undefined,
        selection: undefined,
        material: undefined,
        color: undefined,
      };
    }
    case types.SELECT_APPEARANCE_CATEGORY: {
      const {category, selection, material, color} = action.payload;
      return {...state, category, selection, material, color};
    }
    case types.SET_APPEARANCE: {
      return {...state, looks: action.payload};
    }
    case types.SYNC_APPEARANCE: {
      const {body, looks} = action.payload;

      return {...state, body, looks};
    }
    case types.SET_AVATAR_LOADING: {
      return {...state, avatarLoading: action.payload};
    }
    case types.SET_ASDKID: {
      return {...state, asdkId: action.payload};
    }
    case types.SET_EDITS_IN_PROGRESS: {
      return {...state, editsInProgress: action.payload};
    }
    case types.LOAD_APPEARANCE_DATA: {
      return {...state, appearanceData: action.payload};
    }
    case types.FORCE_BROADCAST: {
      return {...state, forceBroadcast: action.payload};
    }
    case types.SET_IS_FULL_BODY: {
      return {...state, isFullBody: action.payload};
    }
    default:
      return state;
  }
};

export default reducer;
