// chat
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const REFRESH_MESSAGE_TRANSLATIONS = 'REFRESH_MESSAGE_TRANSLATIONS';
export const CLEAR_MESSAGE_TRANSLATIONS = 'CLEAR_MESSAGE_TRANSLATIONS';

// room
export const SET_ROOM_AUDIO_MUTED_STATE = 'SET_ROOM_AUDIO_MUTED_STATE';
export const SET_ROOM_VIDEO_MUTED_STATE = 'SET_ROOM_VIDEO_MUTED_STATE';
export const SET_ROOM_CHAT_MUTED_STATE = 'SET_ROOM_CHAT_MUTED_STATE';
export const SET_ROOM_VOICE_POSITIONAL_MODE_STATE =
  'SET_ROOM_VOICE_POSITIONAL_MODE_STATE';
export const SET_ROOM_VIDEO_POSITIONAL_MODE_STATE =
  'SET_ROOM_VIDEO_POSITIONAL_MODE_STATE';

// consumers
export const ADD_CONSUMER = 'ADD_CONSUMER';
export const REMOVE_CONSUMER = 'REMOVE_CONSUMER';
export const CLEAR_CONSUMERS = 'CLEAR_CONSUMERS';

// peer
export const ADD_PEER = 'ADD_PEER';
export const SET_PEER_AUDIO_MUTED = 'SET_PEER_AUDIO_MUTED';
export const SET_PEER_VIDEO_MUTED = 'SET_PEER_VIDEO_MUTED';
export const SET_PEER_CHAT_MUTED = 'SET_PEER_CHAT_MUTED';
export const SET_PEER_TRANSFORM = 'SET_PEER_TRANSFORM';
export const SET_PEER_PHOTO_URL = 'SET_PEER_PHOTO_URL';
export const SET_PEER_EMOTES = 'SET_PEER_EMOTES';

// me
export const SET_ME_CHAT_MUTED = 'SET_ME_CHAT_MUTED';
export const SET_ME_AUDIO_MUTED = 'SET_ME_AUDIO_MUTED';
export const SET_ME_VIDEO_MUTED = 'SET_ME_VIDEO_MUTED';
export const SET_ME_TRANSFORM = 'SET_ME_TRANSFORM';
export const SET_ME_INFO = 'SET_ME_INFO';
export const SET_ME = 'SET_ME';

// speechToTextService
export const START_AI_TEACHER_LISTENING = "START_AI_TEACHER_LISTENING"
export const STOP_AI_TEACHER_LISTENING = "STOP_AI_TEACHER_LISTENING"

// speechToTextServiceFor OneToOne Meeting
export const START_AI_ONETOONE_MEETING_LISTENING = "START_AI_ONETOONE_MEETING_LISTENING"
export const STOP_AI_ONETOONE_MEETING_LISTENING = "STOP_AI_ONETOONE_MEETING_LISTENING"

// course
export const SHOW_COURSE_COMPLETE_MESSAGE = "SHOW_COURSE_COMPLETE_MESSAGE"
export const HIDE_COURSE_COMPLETE_MESSAGE = "HIDE_COURSE_COMPLETE_MESSAGE"
export const COURSE_COMPLETE = "COURSE_COMPLETE"
export const RESET_COURSE_COMPLETE = "RESET_COURSE_COMPLETE"

