const firebaseConfig = {
  apiKey: "AIzaSyADJSByCz5HOQIOmG_G1RjtQ3Y9kD_f-yk",
  authDomain: "quiklearn-staging.firebaseapp.com",
  projectId: "quiklearn-staging",
  storageBucket: "quiklearn-staging.appspot.com",
  messagingSenderId: "710088753656",
  appId: "1:710088753656:web:6cb19b82c4fa26baebd60d",
  measurementId: "G-PJ1EQV9WB0"
};

export default firebaseConfig;
