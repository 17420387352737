import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import getRemoteConfigValue from 'utils/firebase/remoteConfig';
import { M3D_SCENES_BASE_URL } from 'utils/World/config';

class PlatformWorld extends Component {
  static instance = undefined;

  componentDidMount() {
    PlatformWorld.instance = this;
    window.addEventListener('message', PlatformWorld.onWindowMessage);
    this.iframe = document.getElementById('world-iframe');
    this.iframe.style.border = 'none';
  }

  componentWillUnmount() {
    PlatformWorld.instance = undefined;
    window.removeEventListener('message', PlatformWorld.onWindowMessage);
  }

  static onWindowMessage(event) {
    const { instance } = PlatformWorld;
    if (!instance) return;

    try {
      if (event.data) {
        const { action, route } = event.data;
        if (action || route) {
          instance.onMessageFromWorld(event.data);
        }
      }
    } catch (e) {
      console.error('onWindowMessage() | failed:', e);
    }
  }

  onMessageFromWorld = (message) => {
    const { onTransform, onLegacyMessage, onCommand } = this.props;
    if (message.command) onCommand(message.command);
    if (message.route) {
      message.route === 'j' ? onTransform(message) : onLegacyMessage(message);
    }
  };

  static sendMessage = (message) => {
    const { instance } = PlatformWorld;
    if (!instance || !instance.iframe?.contentWindow) return;
    instance.iframe.contentWindow.postMessage(message, '*');
  };

  render() {
    const webScenesVersion = getRemoteConfigValue('web_scenes_version');
    const url = `${M3D_SCENES_BASE_URL}${webScenesVersion.asString()}/${this.props.location}/index.html`;

    return (
      <Iframe
        rel="preload"
        url={url}
        width="100%"
        height="100%"
        id="world-iframe"
        className="iframe-3dmeet"
        display="initial"
        position="absolute"
        scrolling="no"
        name={this.props.location}
        aria-label={this.props.location}
      />
    );
  }
}

PlatformWorld.defaultProps = {
  onTransform: () => {},
  onLegacyMessage: () => {},
  onCommand: () => {},
  onAppearanceComplete: () => {},
};

PlatformWorld.propTypes = {
  location: PropTypes.string.isRequired,
  onTransform: PropTypes.func,
  onLegacyMessage: PropTypes.func,
  onCommand: PropTypes.func,
  onAppearanceComplete: PropTypes.func,
};

export default PlatformWorld;
