import * as types from './actionTypes';

export const goToLanding = () => {
  return {
    type: types.GO_TO_LANDING,
  };
};

export const goToMeeting = () => {
  return {
    type: types.GO_TO_MEETING,
  };
};

export const goToDeviceConfig = () => {
  return {
    type: types.GO_TO_DEVICE_CONFIG,
  };
};

export const goToAvatarPreview = () => {
  return {
    type: types.GO_TO_AVATAR_PREVIEW,
  };
};

export const setDynamicLink = (link) => {
  return {
    type: types.SET_DYNAMIC_LINK,
    payload: { link },
  };
};

export const useLink = () => {
  return {
    type: types.USE_DYNAMIC_LINK,
  };
};

export const setRemoteConfigLoaded = () => {
  return {
    type: types.SET_REMOTE_CONFIG_LOADED,
  };
};

export const setAllAvailableLevels = (allAvailableLevels) => {
  return {
    type: types.SET_ALL_AVAILABLE_LEVELS,
    payload: allAvailableLevels,
  };
};

export const addAvailableLevel = (level) => {
  return {
    type: types.ADD_AVAILABLE_LEVEL,
    payload: level,
  };
};

export const updateAvailableLevel = ({ level, data }) => {
  return {
    type: types.UPDATE_AVAILABLE_LEVEL,
    payload: { level, data },
  };
};

export const removeAvailableLevel = (level) => {
  return {
    type: types.REMOVE_AVAILABLE_LEVEL,
    payload: level,
  };
};

export const setLevelCacheDirectory = (levelCacheDirectory) => {
  return {
    type: types.SET_LEVEL_CACHE_DIRECTORY,
    payload: levelCacheDirectory,
  };
};

export const setCourseTokenValues = (courseTokenValues) => {
  return {
    type: types.SET_COURSE_TOKEN_VALUES,
    payload: courseTokenValues,
  };
}

export const setCourseInfoValues = (courseInfoValues) => {
  return {
    type: types.SET_COURSE_INFO_VALUES,
    payload: courseInfoValues,
  };
}
export const setCourseProgress = (courseProgress) => {
  return {
    type: types.SET_COURSE_PROGRESS,
    payload: courseProgress,
  };
}
