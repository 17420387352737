import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { joinRoom } from 'store/communication/actions/controlActions';
import { goToMeeting } from 'store/app';

const DeviceConfig = () => {
  const dispatch = useDispatch();

  const onJoin = () => {
    // starts a new instance of the RoomClient for this user which begins the connection to the comm server.
    dispatch(joinRoom());
    // changes the user's view to the meeting view
    dispatch(goToMeeting());
  };

  useEffect(() => {
    onJoin();
  }, []); // The empty dependency array ensures this effect runs once after the component mounts

  // The return statement is now redundant since we're not rendering anything
  return null;
};

export default DeviceConfig;
