import React from 'react';
import PropTypes from 'prop-types';
import {TouchableOpacity, StyleSheet, View, Text, Platform} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {DISABLED_MEETING_CONTROL_COLOR} from 'style/theme';
import Thumbnail from './Thumbnail';

const UniversalButton = (props) => {
  const backgroundColor = props.color;
  const {
    name,
    onPress,
    onPressIn,
    onPressOut,
    onLongPress,
    style,
    badgeStyle,
    testID,
    textBadge,
    iconBadge,
    badgeContent,
    imageSource,
    text,
    iconColor,
    textColor,
    textSize,
    thumbnailStyles,
    disabled,
  } = props;
  const additionalIconStyle = {};
  const additionalTextStyle = {};
  let additionalThumbnailStyle = {};
  const showBadge = textBadge || iconBadge;
  if (iconColor) {
    additionalIconStyle.color = iconColor;
  }
  if (textColor) {
    additionalTextStyle.color = textColor;
  }
  if (textSize) {
    additionalTextStyle.fontSize = textSize;
    additionalTextStyle.height = null;
  }
  if (thumbnailStyles) {
    additionalThumbnailStyle = thumbnailStyles;
  }
  return (
    <TouchableOpacity
      disabled={disabled === undefined ? false : disabled}
      style={[styles.button, {backgroundColor}, style]}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onLongPress={onLongPress}
      testID={testID}>
      {imageSource ? (
        <View style={[styles.stack]}>
          <Thumbnail source={imageSource} style={additionalThumbnailStyle} />
        </View>
      ) : null}
      {name ? (
        <View style={styles.centerText}>
          <View style={styles.content}>
            <Icon name={name} style={[styles.icon, additionalIconStyle]} />
          </View>
        </View>
      ) : null}
      {text ? (
        <View style={styles.stack}>
          <Text style={[styles.text, additionalTextStyle]}>{text}</Text>
        </View>
      ) : null}
      {showBadge ? (
        <View
          style={[
            styles.badgeBackground,
            badgeStyle,
            iconBadge ? styles.badgeIconBackground : {},
          ]}>
          {iconBadge ? (
            <Icon
              name={badgeContent}
              style={[styles.badgeContent, style.badgeIconBackground]}
            />
          ) : (
            <Text style={[styles.badgeContent]}>{badgeContent}</Text>
          )}
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

UniversalButton.defaultProps = {
  color: '#868883',
};

UniversalButton.propTypes = {
  // Background color
  color: PropTypes.string,
  // Icon props
  name: PropTypes.string,
  iconColor: PropTypes.string,
  // Image Props
  imageSource: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  // Text Props
  text: PropTypes.string,
  textColor: PropTypes.string,
  // General props
  onPress: PropTypes.func,
  onPressIn: PropTypes.func,
  onPressOut: PropTypes.func,
  onLongPress: PropTypes.func,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  testID: PropTypes.string,
  // Badge props
  textBadge: PropTypes.bool,
  iconBadge: PropTypes.bool,
  badgeForegroundColor: PropTypes.string,
  badgeBackgroundColor: PropTypes.string,
  badgeStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const styles = StyleSheet.create({
  button: {
    height: 37,
    width: 37,
    borderRadius: 37,
    backgroundColor: '#ffffff',
  },
  icon: {
    color: '#ffffff',
    fontSize: 30,
    textAlign: 'left',
    height: 30,
  },
  text: {
    color: '#ffffff',
    fontSize: 40,
    textAlign: 'center',
    height: 40,
  },
  stack: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
  },
  centerText: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    paddingLeft: 8,
    paddingRight: 9,
    paddingBottom: 3,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  badgeBackground: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: DISABLED_MEETING_CONTROL_COLOR,
  },
  badgeIconBackground: {
    paddingTop: 3,
  },
  badgeContent: {
    color: '#ffffff',
    fontSize: 14,
    textAlign: 'center',
    height: Platform.OS === 'android' ? 16 : 14,
  },
});

export default UniversalButton;
