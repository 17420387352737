import React, {useState, useEffect} from 'react';
import {View, SafeAreaView, StatusBar, Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {goToLanding} from 'store/app';
import {leaveRoom} from 'store/communication/actions/controlActions';

import ConfirmationDialog from 'screens/Meeting/components/LeaveCourseOverlay';
import MeetingWorld from 'screens/Meeting/components/MeetingWorld';
import Controls from 'screens/Meeting/components/Controls';
import RemoteAudios from 'screens/Meeting/components/peers/RemoteAudios';
import ConnectionFailureOverlay from 'components/Communication/ConnectionFailureOverlay';
import SettingsDialogueOverlay from 'components/Communication/SettingsDialogueOverlay';

import PlatformWorld from 'components/World/PlatformWorld';

import worldStyle from 'style/3d';
import styles from 'style/screen';
import {
  MEETING_STATUS_BAR_BACKGROUND_COLOR,
} from 'style/theme';
import RecordingStatus from 'components/Communication/RecordingStatus';
import { QUIKLEARN_URL } from 'config/app.config';
import { hideCourseCompleteMessage, resetCourseComplete } from 'store/communication/actions/courseActions';

const Meeting = () => {
  const dispatch = useDispatch();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const showCourseCompleteMessage = useSelector(state => state.communication.course.showCourseCompleteMessage);
  const courseComplete = useSelector(state => state.communication.course.courseComplete);
  //const isEmailRequired = useSelector(state => state.app.courseTokenValues.collectEmails)
  const isPromotional = useSelector(state => state.app.courseTokenValues.isPromotional)
  const [delayedPromotional, setDelayedPromotional] = useState(false);

  const onClose = () => {
    setShowConfirmationDialog(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedPromotional(isPromotional);
    }, 8000);
    return () => clearTimeout(timer);
  }, [isPromotional]); 

  const handleLeaveConfirmation = (confirmLeave) => {
    dispatch(hideCourseCompleteMessage());
    setShowConfirmationDialog(false);
    if (confirmLeave) {
      dispatch(resetCourseComplete())
      console.log("Attempting to exit course/meeting. Platform: " + Platform.OS)
      if (Platform.OS == 'ios' || Platform.OS == 'android') {
        console.log("Usman on close in start so close media player. ")
        PlatformWorld.closeMediaPlayer();
      } else {
        console.log("Close media player for web-based platform.")
      }

      dispatch(leaveRoom());
      if (Platform.OS == 'ios' || Platform.OS == 'android') {
        dispatch(goToLanding());
      } else {
        console.log("Redirecting to profile page.")
        window.location.href = QUIKLEARN_URL + '/profile/courses';
      }
    }
  };

  const exploreCourse = ()=>{
    window.location.href = QUIKLEARN_URL + '/courses';
  }

  const goToPremium = ()=>{
    window.location.href = QUIKLEARN_URL + '/pricing?specialDiscountClaimed=Y2xhaW0=';
  }
  return (
    <View style={styles.full} pointerEvents="box-none">
      <StatusBar
        backgroundColor={MEETING_STATUS_BAR_BACKGROUND_COLOR}
        barStyle="light-content"
        translucent
      />
      <MeetingWorld />
      <RemoteAudios />
      <SafeAreaView style={worldStyle.safeUI} pointerEvents="box-none">
        <Controls onClose={onClose} />
      </SafeAreaView>

      <RecordingStatus />

      <ConnectionFailureOverlay />

      <SettingsDialogueOverlay />
      {showConfirmationDialog && (
        <ConfirmationDialog
          title="Confirm Leave"
          message="Are you sure you want to leave this course? Your progress has been saved."
          onConfirm={() => handleLeaveConfirmation(true)}
          onCancel={() => handleLeaveConfirmation(false)}
        />
      )}
      {(showCourseCompleteMessage && courseComplete && !isPromotional) &&  (
        <ConfirmationDialog
          title="Course Complete"
          message="Congratulations, you have completed this course! Return to the course list?"
          onConfirm={() => handleLeaveConfirmation(true)}
          onCancel={() => handleLeaveConfirmation(false)}
        />
      )}
      {(showCourseCompleteMessage && courseComplete && delayedPromotional) &&  (
        <ConfirmationDialog
          title="Congratulations on completing the course"
          message="Explore more free courses to enhance your skills or go directly to our subscription page to unlock premium content."
          onConfirm={() => exploreCourse()}
          onCancel={() => goToPremium()}
          confirmParam="Explore Free Courses"
          cancelParam="Get Special Discount"
        />
      )}
    </View>
  );
};

export default Meeting;
