import { SHOW_COURSE_COMPLETE_MESSAGE, HIDE_COURSE_COMPLETE_MESSAGE, COURSE_COMPLETE, RESET_COURSE_COMPLETE } from "../actions/actionTypes"

export const initialState = {
    showCourseCompleteMessage: false,
    courseComplete: false
}

const course = (state = initialState, action) => {

    switch (action.type) {
        case SHOW_COURSE_COMPLETE_MESSAGE:
            return { ...state, showCourseCompleteMessage: true }
        case HIDE_COURSE_COMPLETE_MESSAGE:
            return { ...state, showCourseCompleteMessage: false }
        case COURSE_COMPLETE:
            return { ...state, courseComplete: true }
        case RESET_COURSE_COMPLETE:
            return { ...state, courseComplete: false }
        default:
            return state;
    }
}

export default course;
