import * as types from 'store/world/actionTypes';

export const initialState = {
  location: null,
  initialLoadComplete: false,
  lowPower: false,
  customizeLocation: 'customize_avatar',
  joined: false,
  isMessagePanelOpen: false,
  isMessageNotification: false,
  teleportToPeer: undefined,
  showFloatingNames: false,
  overlays: {},
  labelInfo: null,
  loadStarted: -1,
};

const world = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_WORLD: {
      return initialState;
    }

    case types.SET_WORLD_LOCATION: {
      const {location} = action.payload;

      return {
        ...state,
        initialLoadComplete: true,
        location,
        teleportToPeer: undefined,
        loadStarted: Date.now(),
      };
    }

    case 'SET_CUSTOMIZE_LOCATION': {
      const {location} = action.payload;

      return {...state, customizeLocation: location};
    }

    case 'SET_LOW_POWER': {
      const {lowPower} = action.payload;

      return {...state, lowPower};
    }

    case 'SET_JOINED': {
      const {joined} = action.payload;

      return {...state, joined};
    }

    case 'SET_TELEPORT_TO_PEER': {
      const {peerId} = action.payload;

      return {...state, teleportToPeer: peerId};
    }

    case types.SET_FLOATING_NAMES: {
      const {showFloatingNames} = action.payload;
      return {...state, showFloatingNames: showFloatingNames};
    }

    case types.UPDATE_TEXT_OVERLAY: {
      const {id, text, style, x, y, z, target} = action.payload;

      return {
        ...state,
        overlays: {
          ...state.overlays,
          [id]: {
            text,
            style,
            x,
            y,
            z,
            target,
          },
        },
      };
    }

    case types.REMOVE_TEXT_OVERLAY: {
      const {id} = action.payload;
      const {overlays} = state;

      delete overlays[id];

      return {...state, overlays};
    }

    case types.CLEAR_TEXT_OVERLAYS: {
      // TODO: Use categories for clear?
      return {...state, overlays: {}};
    }

    case types.SET_IS_MESSAGE_PANEL_OPEN: {
      return {...state, isMessagePanelOpen: action.payload};
    }

    case types.SET_IS_MESSAGE_NOTIFICATION: {
      return {...state, isMessageNotification: action.payload};
    }

    case types.SET_LABEL_DISPLAY: {
      if (action.payload === null) {
        return state;
      }

      const labelInfo =
        action.payload === state.labelInfo ? null : action.payload;

      return {
        ...state,
        labelInfo,
      };
    }

    case types.CLEAR_LABEL_DISPLAY: {
      return {
        ...state,
        labelInfo: null,
      };
    }

    case types.CLEAR_LEVEL_LOADING: {
      return {
        ...state,
        loadStarted: -1,
      };
    }

    default:
      return state;
  }
};

export default world;
