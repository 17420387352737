import React from 'react';
import {Platform, StatusBar, View, Alert} from 'react-native';
import {Provider} from 'react-redux';
import {QueryParamProvider} from 'use-query-params';
import Orientation from 'react-native-orientation-locker';
import {isTablet} from 'react-native-device-info';
import FlashMessage from 'react-native-flash-message';
import {
  setJSExceptionHandler,
  setNativeExceptionHandler,
} from 'react-native-exception-handler';
import RNRestart from 'react-native-restart';

import configureStore from 'store';
import RoomClient from 'utils/communication/RoomClient';
import {
  setRoomUrl,
  setRoomFaceDetection,
} from 'store/communication/actions/stateActions';
import Landing from 'screens/Landing';
import styles from 'style/screen';
import recordError from 'components/ErrorHandler/recordError';
import InitializeAppData from './InitializeAppData';
import I18nContextProvider from 'i18n/context/I18nContext';
import { SpeechToTextService } from 'utils/communication/SpeechToTextService';
import { WebboardService } from 'utils/Media/WebboardService';

// Start react-native-exception-handler
if (Platform.OS !== 'web') {
  setJSExceptionHandler((error, isFatal) => {
    recordError(error, isFatal);

    if (isFatal) {
      Alert.alert(
        'Unexpected error occurred',
        'We have reported this to our team! Please try restarting the app.',
        [
          {
            text: 'Restart',
            onPress: () => {
              RNRestart.Restart();
            },
          },
        ],
      );
    }
  });

  const exceptionhandler = (exceptionString) => {
    recordError(Error(exceptionString), true);
  };
  const forceAppQuit = true;
  const executeDefaultHandler = true;

  setNativeExceptionHandler(
    exceptionhandler,
    forceAppQuit,
    executeDefaultHandler,
  );
}
// End react-native-exception-handler

const store = configureStore();

RoomClient.init({store});

const roomUrl = '<is this necessary?>';
store.dispatch(setRoomUrl(roomUrl));

const faceDetection = false;
store.dispatch(setRoomFaceDetection(faceDetection));

// get JSON appearance data for avatar customization - saved to the store.
//store.dispatch(loadAppearanceJsonData());
// get stored appearance object if the user already has one saved to their storage
//store.dispatch(loadAppearanceFromStorage());

const App = () => {
  if (!isTablet()) {
    Orientation.lockToPortrait();
  }

  const baseAppFragment = (
    <I18nContextProvider>
      <Provider store={store}>
        <InitializeAppData />
        <SpeechToTextService/>
        <WebboardService/>
        <StatusBar backgroundColor="#FFF" barStyle="dark-content" translucent />
        <View style={styles.full}>
          <Landing />
          <FlashMessage position="top" />
        </View>
      </Provider>
    </I18nContextProvider>
  );

  const renderApp = () => {
    // Enable dynamic link handling for web via query params
    if (Platform.OS === 'web') {
      return (
      <>
      <QueryParamProvider>{baseAppFragment}</QueryParamProvider>
      </>);
    } else {
      return baseAppFragment;
    }
  };

  return <>{renderApp()}</>;
};

export default App;
