import React from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Text,
  StatusBar,
  ScrollView,
  Button,
  Image,
} from 'react-native';

import QUIKLEARN_LOGO from 'images/FirstScreenImages/Quiklearn_Logo_White.png';
import CONVERSE_WITH_LEGENDS from 'images/FirstScreenImages/converse-with-legends.jpg';
import CUSTOMIZED_LEARNING from 'images/FirstScreenImages/customized-learning-for-everyone.jpg';
import DIVE_INTO_IMMERSIVE_LEARNING from 'images/FirstScreenImages/dive-into-immersive-learning.jpg';

const FirstLaunchScreens = ({ handleGetStartedPress }) => {
    const styles = StyleSheet.create({
        firstLaunchScreen: {
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
            paddingTop: 160,
            alignItems: 'center',
            paddingHorizontal: 20,
            backgroundColor: 'black'
          },
          firstLaunchScreenTitle: {
            color: 'white',
            fontSize: 24,
            marginBottom: 20,
            textAlign: 'left',
          },
          firstLaunchScreenDescription: {
            color: 'white',
            fontSize: 18,
            marginBottom: 10,
            textAlign: 'left',
            width: '100%',
          },
          swipeIndicator: {
            color: 'white',
            fontSize: 16,
            position: 'absolute',
            bottom: 30,
            alignSelf: 'center',
          },
    });

    const firstScreens = (
        <View style={[styles.container, styles.backgroundStyle]}>
            <StatusBar
            backgroundColor="#000000"
            barStyle={'light-content'}
            showHideTransition={'fade'}
            hidden={false}
            />
            <ScrollView horizontal pagingEnabled>
            <View style={styles.firstLaunchScreen}>
                <Image
                source={QUIKLEARN_LOGO}
                style={{width: 300, height: 100, marginBottom: 5, marginTop: 150, alignItems: 'center'}}
                resizeMode="contain"
                />
                <Text style={[styles.firstLaunchScreenDescription, {width: '100%', marginTop: 25, fontSize: 20, textAlign: 'center',}]}>Immersive Learning, powered by AI</Text>
                <Text style={styles.swipeIndicator}>Swipe left to continue</Text>
            </View>
            <View style={styles.firstLaunchScreen}>
                <Image
                source={DIVE_INTO_IMMERSIVE_LEARNING}
                style={{width: 250, height: 250, marginBottom: 30, borderRadius: 20, alignContent: 'center'}}
                resizeMode="contain"
                />
                <Text style={styles.firstLaunchScreenTitle}>⚛️ Dive into Immersive Learning</Text>
                <Text style={styles.firstLaunchScreenDescription}>Embark on Engaging, Gamified Lessons</Text>
                <Text style={styles.firstLaunchScreenDescription}>Dive into captivating 3D worlds, from the vibrant beaches of Cabo San Lucas to the ancient ruins of Chichen Itza, and learn through immersive, context-rich experiences. Transform your education into an exciting journey of discovery!</Text>
                <Text style={styles.swipeIndicator}>Swipe left to continue</Text>
            </View>
            <View style={styles.firstLaunchScreen}>
                <Image
                source={CONVERSE_WITH_LEGENDS}
                style={{width: 250, height: 250, marginBottom: 30, borderRadius: 20}}
                resizeMode="contain"
                />
                <Text style={styles.firstLaunchScreenTitle}>🗣️ Interactive Teaching from History’s Brightest Minds</Text>
                <Text style={styles.firstLaunchScreenDescription}>Meet Your Personal AI Luminary in Their World</Text>
                <Text style={styles.firstLaunchScreenDescription}>Customize your avatar and step into unique 3D settings to converse with great thinkers of the past and present, all brought to life with their distinctive voices and personalities. Choose from a diverse roster of historical and contemporary AI characters to guide your learning journey.</Text>
                <Text style={styles.swipeIndicator}>Swipe left to continue</Text>
            </View>
            <View style={styles.firstLaunchScreen}>
                <Image
                source={CUSTOMIZED_LEARNING}
                style={{width: 250, height: 250, marginBottom: 30, borderRadius: 20}}
                resizeMode="contain"
                />
                <Text style={styles.firstLaunchScreenTitle}>🗺️ Visual Learning at Its Finest</Text>
                <Text style={styles.firstLaunchScreenDescription}>Grasp Complex Concepts in a Secure Environment</Text>
                <Text style={styles.firstLaunchScreenDescription}>From delving into the intricacies of human cells to unraveling the mysteries of coding, Quiklearn offers a safe and supportive space to visualize and comprehend complex topics like never before.</Text>
                <Text style={styles.swipeIndicator}>Swipe left to continue</Text>
            </View>
            <View style={styles.firstLaunchScreen}>
            <Image
                source={{ uri: 'https://mcusercontent.com/d011ed4916f6aff40e65fa8cb/images/d193db5d-a526-9abe-b3d0-27c0218eb1c7.png' }}
                style={{width: 300, height: 300, marginBottom: 30, borderRadius: 20}}
                resizeMode="contain"
                />
                <Text style={[styles.firstLaunchScreenTitle, {textAlign: 'auto', fontWeight: 'bold'}]}>So, are you ready to embark on this groundbreaking journey with us?</Text>
                <Button
                title="Get Started"
                onPress={handleGetStartedPress}
                color="#C5E836"
                fontSize="36"
                fontWeight="bold"
                />
            </View>
            </ScrollView>
        </View>
    );

    return firstScreens;
}

export default FirstLaunchScreens;
