import React, {useEffect, useState} from 'react';
import {
  SafeAreaView,
  Platform,
  StyleSheet,
  View,
  Dimensions,
  ActivityIndicator,
  Text,
  StatusBar,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import SplashScreen from 'react-native-splash-screen';
import Meeting from 'screens/Meeting';
import DeviceConfig from 'screens/DeviceConfig';
import AvatarPreview from 'screens/AvatarPreview';
import ErrorHandler from 'components/ErrorHandler';
import {logScreenView} from 'utils/firebase/analytics';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WebView } from 'react-native-webview';
import {initDynamicLinkHandler} from 'store/app/linkActions';
import { QUIKLEARN_URL, MEETING_URL_PREFIX } from 'config/app.config';
import FirstLaunchScreens from 'screens/FirstLaunchScreens';

const setFirstLaunchFlag = async () => {
  try {
    await AsyncStorage.setItem('hasLaunched', 'true');
  } catch (e) {
    // handle saving error
  }
};

const checkFirstLaunch = async () => {
  try {
    const hasLaunched = await AsyncStorage.getItem('hasLaunched');
    console.log('checkFirstLaunch hasLaunched:', hasLaunched);
    if (hasLaunched === null) {
      return false;
    } else if (hasLaunched === 'false') {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    // handle reading error
    return false;
  }
};

const Landing = () => {
  const dispatch = useDispatch();
  const dynamicLinkState = useSelector((state) => state.app.dynamicLinkState);
  const link = useSelector((state) => state.app.link);
  const [showFirstLaunchScreens, setShowFirstLaunchScreens] = useState(false)
  const [onCoursesScreen, setOnCoursesScreen] = useState(false)

  useEffect(() => {
    checkFirstLaunch().then((hasLaunched) => {
      setShowFirstLaunchScreens(!hasLaunched);
    });

    // hide the splash screen when there is no dynamic link on mobile
    if (dynamicLinkState === 'ready') {
      if (!link) {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          SplashScreen.hide();
        }
      }
    }
  }, [dynamicLinkState, link]);
  const meeting = <Meeting />;
  const deviceConfig = <DeviceConfig />;
  const avatarPreview = <AvatarPreview />;

  const handleNavigationStateChange = async (navState) => {
    const { url } = navState;

    if (url.startsWith(QUIKLEARN_URL + "/profile/courses")) {
      setOnCoursesScreen(true);
      console.log('onCoursesScreen:', onCoursesScreen);
      console.log('url:', url);
    } else if (url.startsWith(QUIKLEARN_URL + "/profile")) {
      setOnCoursesScreen(true);
      console.log('onCoursesScreen:', onCoursesScreen);
      console.log('url:', url);
    } else {
      setOnCoursesScreen(false);
      console.log('onCoursesScreen:', onCoursesScreen);
      console.log('url:', url);
    }

    // Check if URL matches the specific pattern
    if (url.startsWith(MEETING_URL_PREFIX)) {
      // Parse the link for token and other details
      dispatch(initDynamicLinkHandler(url));
    }
  };

  const handleGetStartedPress = () => {
    setShowFirstLaunchScreens(false);
    setFirstLaunchFlag();
  };

  const styles = StyleSheet.create({
    flexContainer: {
      flex: 1,
    },
    fullScreenLoadingContainer: {
      position: 'absolute',
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000000',
      color: "#C5E836",
      fontWeight: 'bold',
    },
    container: {
      flex: 1,
      backgroundColor: '#000000',
      paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
    },
    backgroundStyle: {
      backgroundColor: onCoursesScreen ? "#161616" : "#000000",
      borderTopColor: 'black',
      borderTopWidth: 0,
      borderBottomColor: 'black',
      borderBottomWidth: 0
    },
  });

  const webViewErrorPresent = false;
  const handleWebViewError = (syntheticEvent) => {
    console.warn('WebView error: ', nativeEvent);
    webViewErrorPresent = true;
  }
  const errorText = 'Unable to connect to Quiklearn, please check your internet connection!';

  const landing = (
    <SafeAreaView style={[styles.container, styles.backgroundStyle]}>
    <StatusBar
      backgroundColor={ onCoursesScreen ? '#161616' : '#000000' }
      barStyle={'light-content'}
      showHideTransition={'fade'}
      hidden={false}
    />
      {webViewErrorPresent ? (
      <View>
        <Text style={styles.fullScreenLoadingContainer}>{errorText}</Text>
      </View>
      ) : (
      <WebView
        source={{ uri: QUIKLEARN_URL + "/profile/courses"}}
        webviewDebuggingEnabled={true}
        style={styles.flexContainer}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        startInLoadingState={true}
        renderLoading={() => (
          <View style={styles.fullScreenLoadingContainer}>
            <ActivityIndicator size="large" color="#C5E836" />
          </View>
        )}
        userAgent={Platform.OS === 'android' ? 'Chrome/18.0.1025.133 Mobile Safari/535.19' : 'AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75'}
        allowsBackForwardNavigationGestures={true}
        originWhitelist={[ QUIKLEARN_URL, 'https://*']}
        javaScriptCanOpenWindowsAutomatically={true}
        onNavigationStateChange={handleNavigationStateChange}
        onLoadProgress = {e => {
          let {url} = e?.nativeEvent;
          if (url.startsWith(QUIKLEARN_URL + '/profile/courses')) {
            setOnCoursesScreen(true);
          } else {
            setOnCoursesScreen(false);
          }
        }}
        onError={handleWebViewError}
      />
      )}
    </SafeAreaView>
  );

  const screen = useSelector((state) => state.app.screen);
  let currentScreen;
  if (showFirstLaunchScreens && (Platform.OS === 'ios' || Platform.OS === 'android')) {
    currentScreen = <FirstLaunchScreens handleGetStartedPress={handleGetStartedPress} />;
  } else {
    switch (screen) {
      case 'meeting':
        currentScreen = meeting;
        break;
      case 'deviceConfig':
        currentScreen = deviceConfig;
        break;
      case 'avatarPreview':
        currentScreen = avatarPreview;
        break;
      default:
        currentScreen = landing;
        break;
    }
  }

  useEffect(() => {
    const logScreenToFirebaseAnalytics = async () => {
      await logScreenView(screen);
    };

    logScreenToFirebaseAnalytics();
  }, [screen]);

  return <ErrorHandler>{currentScreen}</ErrorHandler>;
};

export default Landing;
