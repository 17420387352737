import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Keyboard, ScrollView, StyleSheet, View, Platform, Animated, KeyboardAvoidingView, TouchableOpacity, Text, Dimensions, Modal, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { isTablet } from 'react-native-device-info';
import Orientation from 'react-native-orientation-locker';
import { showMessage } from 'react-native-flash-message';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import FAIcon from 'react-native-vector-icons/FontAwesome';

import { I18nContext } from 'i18n/context/I18nContext';
import World from 'components/World';
import WorldSettingsListItems from 'screens/Meeting/components/SideBar/Settings/WorldSettingsListItems';
import SidePanel from 'screens/Meeting/components/SidePanel';

import rightArrow from 'images/CustomizationIcons/rightArrow.png';
import closeIcon from 'images/CustomizationIcons/close.png';
import { startOneOnOneMeeting, stopOneOnOneMeeting } from 'store/communication/actions/speechToTextServiceActions';


const Controls = ({ onClose }) => {

  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();

  const [openEmotes, setOpenEmotes] = useState(false);
  const [isSitting, setIsSitting] = useState(false);
  const [isHandUp, setIsHandUp] = useState(false);
  const [isFlying, setIsFlying] = useState(false);
  const [isLaserOn, setIsLaserOn] = useState(false);
  const [isShowingSettings, setIsShowingSettings] = useState(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [isViewboardMode, setIsViewboardMode] = useState(false);
  const [isWebboardMode, setIsWebboardMode] = useState(false);
  const [isQuestionMode, setIsQuestionMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOneModelVisible, setIsOneModalVisible] = useState(false);
  const [firstModelVisible, setFirstModelVisible] = useState(false);
  const [isOneToOneMeetingMode, setOneToOneMeetingMode] = useState(false);

  const courseComplete = useSelector(state => state.communication.course.courseComplete);
  const courseProgress = useSelector(state => state.app.courseTokenValues.courseProgress);
  const courseId = useSelector(state => state.app.courseTokenValues.courseId);
  const userId = useSelector(state => state.app.courseTokenValues.userId);
  const isPromotional = useSelector(state => state.app.courseTokenValues.isPromotional);
  const [isPortrait, setIsPortrait] = useState(Dimensions.get('window').width < Dimensions.get('window').height);
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const [screenHeight, setScreenHeight] = useState(Dimensions.get('window').height);

  useEffect(() => {
    const updateOrientation = ({ window }) => {
      console.log('updateOrientation with width: ', window.width, ' and height: ', window.height);
      setScreenWidth(window.width);
      setScreenHeight(window.height); 
      setIsPortrait(window.width < window.height);
    };

    // Add the event listener
    Dimensions.addEventListener('change', updateOrientation);
    // Remove the event listener on cleanup
    return () => {
      Dimensions.removeEventListener('change', updateOrientation);
    };
  }, []);

  const location = useSelector((state) => state.world.location);
  const [currentLocation, setCurrentLocation] = useState(location);

  const scaleAnim = useRef({
    handUp: new Animated.Value(0),
    sitting: new Animated.Value(0),
    laser: new Animated.Value(0),
    flying: new Animated.Value(0),
    jump: new Animated.Value(0),
    exit: new Animated.Value(0),
  }).current;

  useEffect(() => {
    const clearEmoteState = () => {
      setOpenEmotes(false);
      setIsSitting(false);
      setIsHandUp(false);
      setIsFlying(false);
      setIsLaserOn(false);

      World.runCommand('perform_emote sit false');
      World.runCommand('perform_emote handraise false');
      World.runCommand('perform_emote fly false');
      World.runCommand('perform_emote laser false');
    };

    if (currentLocation !== location) {
      clearEmoteState();
      setCurrentLocation(location);
    }
  }, [currentLocation, location]);

  useEffect(() => {
    if (openEmotes) {
      // Animate icons to appear
      const animations = Object.keys(scaleAnim).map(key =>
        Animated.timing(scaleAnim[key], {
          toValue: 1,  // Scale to full size
          duration: 20,
          useNativeDriver: true,
        })
      );
      Animated.sequence(animations).start();
    } else {
      // Animate icons to disappear
      const animationsClose = Object.keys(scaleAnim).map(key =>
        Animated.timing(scaleAnim[key], {
          toValue: 0,
          duration: 30,
          useNativeDriver: true,
        })
      );
      Animated.sequence(animationsClose).start();
    }
  }, [openEmotes, scaleAnim]);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => setKeyboardVisible(true)
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => setKeyboardVisible(false)
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);


  const toggleViewboardMode = () => {
    setIsViewboardMode(!isViewboardMode);
    World.runCommand(`viewboard_fullscreen viewboard ${!isViewboardMode}`);
  };

  const toggleWebboardMode = () => {
    setIsWebboardMode(!isWebboardMode);
    World.runCommand(`set_webboard_visibility ${!isWebboardMode}`);
  };

  const toggleEmotes = () => {
    setOpenEmotes(!openEmotes);
  };

  const toggleSettings = () => {
    setIsShowingSettings(!isSidePanelOpen);
    setIsSidePanelOpen(!isSidePanelOpen);
  };
  const toggleOneToOneMeetingMode = () => {
    toggleEuqations(isQuestionMode, !isOneToOneMeetingMode, false);
    setOneToOneMeetingMode(!isOneToOneMeetingMode);
    World.runCommand(`onetoone ${!isOneToOneMeetingMode}`);
    console.log('toggleOneToOneMeetingMode', isOneToOneMeetingMode);
    if (!isOneToOneMeetingMode) {
      dispatch(startOneOnOneMeeting());
    } else {
      dispatch(stopOneOnOneMeeting());
    }
  };

  const toggleEuqations = useCallback((isQAMode, isOneTOOneMode, flag = false) => {
    console.log('toggleEuqations', isQAMode, isOneTOOneMode, flag);

    if (isQAMode && flag) {
      if (isOneTOOneMode) {
        setOneToOneMeetingMode(!isOneTOOneMode);
        World.runCommand(`onetoone ${!isOneTOOneMode}`);
        dispatch(stopOneOnOneMeeting());
      }
    } else if (isOneTOOneMode && !flag) {
      if (isQAMode) {
        setIsQuestionMode(!isQAMode);
        World.runCommand(`ask_question ${!isQAMode}`);
      }
    }
  }, [])
  const toggleQuestion = () => {
    toggleEuqations(!isQuestionMode, isOneToOneMeetingMode, true);
    setIsQuestionMode(!isQuestionMode);
    World.runCommand(`ask_question ${!isQuestionMode}`);
  };

  const checkUserFirstTime = () => {
    const storageKey = `CO_STR_${courseId}`;
    const storedValue = localStorage.getItem(storageKey);
    if (storedValue) {
      setIsModalVisible(false);
      setIsOneModalVisible(false);
    } else if (courseProgress != undefined && courseProgress == "1") {
      localStorage.setItem(storageKey, 'true');
      setIsModalVisible(true);
      setFirstModelVisible(true);
    }
  }

  useEffect(() => {
    checkUserFirstTime();
    const storageKey = `CO_STR_${courseId}_${userId}_ONETOONE`;
    const storedValue = localStorage.getItem(storageKey);
    if(storedValue){
      setIsOneModalVisible(false);
    }
    else if(firstModelVisible && courseProgress == "2"){
      localStorage.setItem(storageKey, 'true');
      setIsOneModalVisible(true);
    }
  }, [courseProgress]);

  const toggleEmotesLongPress = () => {
    showMessage({
      message: translate('emotes.emoteDesc'),
      hideStatusBar: Platform.OS === 'android' ? true : false,
      type: 'info',
      icon: 'auto',
      duration: 4000,
    });
  };

  const toggleFlying = () => {
    setIsFlying(!isFlying);
    World.runCommand(`perform_emote fly ${!isFlying}`);
  };

  const toggleSitting = () => {
    setIsSitting(!isSitting);
    World.runCommand(`perform_emote sit ${!isSitting}`);
  };

  const toggleHandUp = () => {
    setIsHandUp(!isHandUp);
    World.runCommand(`perform_emote handraise ${!isHandUp}`);
  };

  const onJump = () => {
    World.runCommand('perform_emote jump');
  };

  const toggleLaser = () => {
    setIsLaserOn(!isLaserOn);
    World.runCommand(`perform_emote laser ${!isLaserOn}`);
  };

  const onFlyUp = () => {
    World.runCommand('perform_emote altitude true');
    flyingUpTimeout = setTimeout(onFlyUp, FLYING_TIMEOUT_INTERVAL);
  };

  const onStopFlyUp = () => {
    clearTimeout(flyingUpTimeout);
  };

  const onFlyDown = () => {
    World.runCommand('perform_emote altitude false');
    flyingDownTimeout = setTimeout(onFlyDown, FLYING_TIMEOUT_INTERVAL);
  };

  const onStopFlyDown = () => {
    clearTimeout(flyingDownTimeout);
  };

  const toggleSidePanel = (isOpen) => {
    setIsSidePanelOpen(isOpen);
  };

  const FLYING_TIMEOUT_INTERVAL = 35;
  let flyingUpTimeout = null;
  let flyingDownTimeout = null;

  const emotesTranslucency = openEmotes ? 1 : 0.5;
  const sittingTranslucency = isSitting ? 1 : 0.5;
  const handUpTranslucency = isHandUp ? 1 : 0.5;
  const flyingTranslucency = isFlying ? 1 : 0.5;
  const laserTranslucency = isLaserOn ? 1 : 0.5;
  const settingsTranslucency = isSidePanelOpen ? 1 : 0.5;
  const viewboardTranslucency = isViewboardMode ? 1 : 0.5;
  const webboardTranslucency = isWebboardMode ? 1 : 0.5;
  const questionTranslucency = isQuestionMode ? 1 : 0.5;
  const oneToOneMeetingTranslucency = isOneToOneMeetingMode ? 1 : 0.5;
  const iconRef = useRef(null);


  const [iconPosition, setIconPosition] = useState({ x:0, y:0, width:0, height:0, left: 0, top: 0 });
  const [iconOnePosition, setIconOnePosition] = useState({ x:0, y:0, width:0, height:0, left: 0, top: 0 });
  const handleLayout = (event) => {
    if (event && event.nativeEvent) {
      const { x, y, width, height, left, top } = event.nativeEvent.layout;
      console.log(event.nativeEvent.layout);
      setIconPosition({ x, y, width, height, left, top});
    }
  };

  const handleOneLayout = (event) => {
    if (event && event.nativeEvent) {
      const { x, y, width, height, left, top } = event.nativeEvent.layout;
      console.log(event.nativeEvent.layout);
      setIconOnePosition({ x, y, width, height, left, top});
    }
  };

  const isViewboardContentFullScreen = useSelector(
    (state) => state.viewboard.isViewboardContentFullScreen,
  );

  const [currentOrientation, setCurrentOrientation] = useState(
    Orientation.getInitialOrientation(),
  );

  useEffect(() => {
    const onOrientationDidChange = (orientation) => {
      if (currentOrientation !== orientation) {
        setCurrentOrientation(orientation);
      }
    };
    Orientation.addOrientationListener(onOrientationDidChange);

    return () => {
      Orientation.removeOrientationListener(onOrientationDidChange);
    };
  }, [currentOrientation]);

  const styles = StyleSheet.create({
    buttons: {
      margin: 4,
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.3,
      shadowRadius: 5,
      elevation: 6,
    },
    container: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      flex: 1,
      alignItems: 'center',
      backgroundColor: 'transparent',
    },
    actionContainer: {
      position: 'absolute',
      alignItems: 'flex-end',
      flexDirection: isPortrait ? 'column' : Platform.OS === 'web' ? 'column' : 'row',
      right: 50,
      top: 90,
    },
    jumpButton: {
      position: 'absolute',
      right: Platform.OS === 'web' ? 85 : 53,
      bottom: 0,
      opacity: 0.65
    },
    flyUpButton: {
      position: 'absolute',
      right: Platform.OS === 'web' ? 85 : 53,
      bottom: 0,
      opacity: 0.65
    },
    flyDownButton: {
      position: 'absolute',
      right: Platform.OS === 'web' ? 170 : 106,
      bottom: 0,
      opacity: 0.65
    },
    full: {
      flex: 1,
    },
    container: {
      flex: 1,
    },
    panel: {
      position: 'absolute',
      width: Platform.OS === 'web' || isTablet() ? 375 : 300,
      height: '100%',
      right: 0,
      top: 0,
      flex: 1,
      opacity: 0.9,
      shadowColor: "#000",
      shadowOffset: { width: 4, height: 4 },
      shadowOpacity: 0.3,
      shadowRadius: 5,
      elevation: 6,
    },
    badgeIconBackground: {
      paddingTop: 3,
    },
    badgeContent: {
      color: '#ffffff',
      fontSize: 14,
      textAlign: 'center',
      height: Platform.OS === 'android' ? 16 : 14,
    },
    button: {
      height: Platform.OS === 'web' ? 60 : 38,
      width: Platform.OS === 'web' ? 60 : 38,
      borderRadius: Platform.OS === 'web' ? 60 : 38,
      backgroundColor: '#ffffff',
    },
    icon: {
      paddingTop: Platform.OS === 'web' ? 6 : 5,
      color: '#ffffff',
      fontSize: Platform.OS === 'web' ? 45 : 28,
      textAlign: 'center',
      height: Platform.OS === 'web' ? 55 : 34,
    },
    exitIcon: {
      paddingTop: Platform.OS === 'web' ? -30 : 1,
      color: 'white',
      fontSize: Platform.OS === 'web' ? 50 : 28,
      textAlign: 'center',
      height: Platform.OS === 'web' ? 58.5 : 34,
      fontWeight: 'bold',
      transform: [{ rotate: '180deg' }],
    },
    text: {
      fontSize: Platform.OS === 'web' ? 20 : 12,
      textAlign: 'center',
      height: 40,
      color: 'black',
      paddingTop: Platform.OS === 'web' ? 2.5 : 10,
      fontWeight: 'bold',
    },
    stack: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      justifyContent: 'center',
    },
    buttonContainer: {
      position: 'absolute',
      right: isSidePanelOpen ? 250 : 3,
      bottom: keyboardVisible ? 75 : Platform.OS === 'web' ? 350 : isPortrait ? 175 : Dimensions.get('window').height - 100,
      alignItems: 'flex-end',
      flexDirection: isPortrait ? 'column' : Platform.OS === 'web' ? 'column' : 'row',
      top:10
    },
    modalContent: {
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: 10,
      width: 200,
      height: 'auto',
      padding: 20,
    },
    modalText: {
      color: '#fff',
      fontSize: 24,
      marginBottom: 10,
      textAlign: 'left',
    },
    modalTextDesc: {
      color: '#fff',
      fontSize: 18,
      marginBottom: 10,
      textAlign: 'left',
    },
    closeIconContainer: {
      position: 'absolute',
      top: 10,
      right: 10,
      padding: 0,
    },
    closeIcon: {
      width: 20,
      height: 20,
    },
    rightArrow: {
      height: 50,
      width: 70,
      position: 'absolute',
      transform: [{ rotate: '45deg' }],
    },
  });

  const ActionsButtonsView = () => {

    return (
      <>
        <Animated.View style={{ transform: [{ scale: scaleAnim.flying }] }}>
          <TouchableOpacity
            style={[styles.button, { backgroundColor: '#C5E836', opacity: flyingTranslucency }, styles.buttons]}
            onPress={toggleFlying}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>
                <Icon name={"airplane"} style={[styles.icon, { color: 'black' }]} />
              </View>
            </View>
          </TouchableOpacity>
        </Animated.View>
        <Animated.View style={{ transform: [{ scale: scaleAnim.laser }] }}>
          <TouchableOpacity
            style={[styles.button, { backgroundColor: '#C5E836', opacity: laserTranslucency }, styles.buttons]}
            onPress={toggleLaser}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>
                <Icon name={"laser-pointer"} style={[styles.icon, { color: 'black' }]} />
              </View>
            </View>
          </TouchableOpacity>
        </Animated.View>
        <Animated.View style={{ transform: [{ scale: scaleAnim.sitting }] }}>
          <TouchableOpacity
            style={[styles.button, { backgroundColor: '#C5E836', opacity: sittingTranslucency }, styles.buttons]}
            onPress={toggleSitting}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>
                <Icon name={"seat"} style={[styles.icon, { color: 'black' }]} />
              </View>
            </View>
          </TouchableOpacity>
        </Animated.View>
        <Animated.View style={{ transform: [{ scale: scaleAnim.handUp }] }}>
          <TouchableOpacity
            style={[styles.button, { backgroundColor: '#C5E836', opacity: handUpTranslucency }, styles.buttons]}
            onPress={toggleHandUp}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>
                <Icon name={"human-greeting"} style={[styles.icon, { color: 'black' }]} />
              </View>
            </View>
          </TouchableOpacity>
        </Animated.View>
        <Animated.View style={{ transform: [{ scale: scaleAnim.handUp }] }}>
          {!isFlying && (
            <TouchableOpacity
              style={[styles.jumpButton, styles.button, { backgroundColor: '#C5E836', opacity: handUpTranslucency }, styles.buttons]}
              onPress={onJump}
            >
              <View style={styles.centerText}>
                <View style={styles.content}>
                  <Icon name={"chevron-double-up"} style={[styles.icon, { color: 'black' }]} />
                </View>
              </View>
            </TouchableOpacity>
          )}
        </Animated.View>
        {isFlying ? (
          <>
            <TouchableOpacity
              style={[styles.flyUpButton, styles.button, { backgroundColor: '#C5E836' }, styles.buttons]}
              onPressIn={onFlyDown}
              onPressOut={onStopFlyDown}
            >
              <View style={styles.centerText}>
                <View style={styles.content}>
                  <Icon name={"airplane-landing"} style={[styles.icon, { color: 'black' }]} />
                </View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.flyDownButton, styles.button, { backgroundColor: '#C5E836' }, styles.buttons]}
              onPressIn={onFlyUp}
              onPressOut={onStopFlyUp}
            >
              <View style={styles.centerText}>
                <View style={styles.content}>
                  <Icon name={"airplane-takeoff"} style={[styles.icon, { color: 'black' }]} />
                </View>
              </View>
            </TouchableOpacity>
          </>
        ) : null}
      </>
    );
  };

  return (
    <KeyboardAvoidingView
      pointerEvents="box-none"
      style={{ flex: 1}}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <View style={styles.full} pointerEvents="box-none">
        {isSidePanelOpen ? (
          <SidePanel onPanelStateChange={toggleSidePanel}>
            <ScrollView style={styles.container}>
              <WorldSettingsListItems />
            </ScrollView>
          </SidePanel>
        ) : null}
        <View style={styles.buttonContainer}>
          {
            openEmotes ? (
              <ActionsButtonsView />
            ) : null
          }
          <TouchableOpacity
            style={[styles.button, { backgroundColor: '#d93025', opacity: 0.65 }, styles.buttons]}
            onPress={() => onClose()}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>
                <Icon name={"exit-to-app"} style={[styles.exitIcon]} />
              </View>
            </View>
          </TouchableOpacity>
          {/* <TouchableOpacity
            style={[styles.button, {backgroundColor: '#C5E836', opacity: settingsTranslucency}, styles.buttons]}
            onPress={toggleSettings}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>
                <Icon name={"cog-outline"} style={[styles.icon, {color: 'black'}]} />
              </View>
            </View>
          </TouchableOpacity> */}
          <TouchableOpacity
            style={[styles.button, { backgroundColor: '#C5E836', opacity: emotesTranslucency }, styles.buttons]}
            onPress={toggleEmotes}
            onLongPress={toggleEmotesLongPress}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>
                <Icon name={"walk"} style={[styles.icon, { color: 'black' }]} />
              </View>
            </View>
          </TouchableOpacity>
          {Platform.OS === 'web' ? (
            <TouchableOpacity
              style={[styles.button, { backgroundColor: '#C5E836', opacity: webboardTranslucency }, styles.buttons]}
              onPress={toggleWebboardMode}
            >
              <View style={styles.centerText}>
                <View style={styles.content}>
                  <Icon name={"web"} style={[styles.icon, { color: 'black' }]} />
                </View>
              </View>
            </TouchableOpacity>
          ) : null}
          <TouchableOpacity
            style={[styles.button, { backgroundColor: '#C5E836', opacity: viewboardTranslucency }, styles.buttons]}
            onPress={toggleViewboardMode}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>
                <Icon name={"presentation"} style={[styles.icon, { color: 'black' }]} />
              </View>
            </View>
            <View style={styles.stack}>
              <Text style={[styles.text]}>{useSelector((state) => state.app.courseTokenValues.courseProgress)}</Text>
            </View>
          </TouchableOpacity>
          {!courseComplete ? (
            <TouchableOpacity
              style={[styles.button, { backgroundColor: '#C5E836', opacity: questionTranslucency, position: "relative" }, styles.buttons]}
              onPress={toggleQuestion}
              onLayout={handleLayout}

            >
              <View style={styles.centerText}>
                <View style={styles.content}>
                  <Icon
                    ref={iconRef}
                    id={"questionIcon"}
                    name={"message-question-outline"}
                    style={[styles.icon, { color: 'black' }]}
                  />
                </View>
              </View>
              <Modal
                visible={isModalVisible && isPromotional && !courseComplete} // Show modal if isModalVisible is true and course is not complete
                transparent={true}
                onRequestClose={() => setIsModalVisible(false)} // Close modal on back button press (Android)
              >
                <View style={[
                  styles.modalContent,
                  iconPosition && {
                    position: 'absolute',
                    left: screenWidth - iconPosition.width - 245, // Adjust top position
                    bottom: screenHeight - iconPosition.top + iconPosition.height/2, // Adjust left position
                  }
                ]}>
                  <TouchableOpacity
                    style={styles.closeIconContainer}
                    onPress={() => setIsModalVisible(false)} // Close modal when close button is pressed
                  >
                    <Image
                      style={styles.closeIcon}
                      source={closeIcon}
                    />
                  </TouchableOpacity>

                  <View style={styles.modalBox}>
                    <Text style={styles.modalText}>Welcome!</Text>
                    <Text style={styles.modalTextDesc}>Pause the course at any time to ask the instructor a question!</Text>
                  </View>

                  <Image
                    style={[
                      styles.rightArrow,
                      iconPosition && {
                        bottom: -50, // Adjust top position
                        right: -55, // Adjust left position
                      }
                    ]}
                    source={rightArrow}
                  />
                </View>
              </Modal>
            </TouchableOpacity>
          ) : null}
          <TouchableOpacity
            style={[styles.button, { backgroundColor: '#C5E836', opacity: oneToOneMeetingTranslucency }, styles.buttons]}
            onPress={toggleOneToOneMeetingMode}
            onLayout={handleOneLayout}
          >
            <View style={styles.centerText}>
              <View style={styles.content}>

                <i className="fas fa-people-arrows"
                  id='oneToOne'
                  style={{
                    ...styles.icon,
                    paddingTop: Platform.OS === 'web' ? 10 : 5,
                    color: '#ffffff',
                    fontSize: Platform.OS === 'web' ? 40 : 28,
                    textAlign: 'center',
                    height: Platform.OS === 'web' ? 45 : 34,
                    color: 'black'
                  }}
                ></i>
                {/* <FAIcon name={"people-arrows"} style={[styles.icon, { color: 'black' }]} /> */}


              </View>
            </View>
            <Modal
                visible={isOneModelVisible && isPromotional && !courseComplete} // Show modal if isModalVisible is true and course is not complete
                transparent={true}
                onRequestClose={() => setIsOneModalVisible(false)} // Close modal on back button press (Android)
              >
                <View style={[
                  styles.modalContent,
                  iconOnePosition && {
                    position: 'absolute',
                    left: screenWidth - iconOnePosition.width - 245, // Adjust top position
                    bottom: screenHeight - iconOnePosition.top + iconOnePosition.height/2, // Adjust left position
                  }
                ]}>
                  <TouchableOpacity
                    style={styles.closeIconContainer}
                    onPress={() => setIsOneModalVisible(false)} // Close modal when close button is pressed
                  >
                    <Image
                      style={styles.closeIcon}
                      source={closeIcon}
                    />
                  </TouchableOpacity>

                  <View style={styles.modalBox}>
                    <Text style={styles.modalText}>One to One Meeting</Text>
                    <Text style={styles.modalTextDesc}>Pause the course at any time to start conversation with the instructor!</Text>
                  </View>

                  <Image
                    style={[
                      styles.rightArrow,
                      iconOnePosition && {
                        bottom: -50, // Adjust top position
                        right: -55, // Adjust left position
                      }
                    ]}
                    source={rightArrow}
                  />
                </View>
              </Modal>
          </TouchableOpacity>
        </View>

      </View>
    </KeyboardAvoidingView>
  );
};

Controls.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Controls;
