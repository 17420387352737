import { SHOW_COURSE_COMPLETE_MESSAGE, HIDE_COURSE_COMPLETE_MESSAGE, COURSE_COMPLETE, RESET_COURSE_COMPLETE } from "./actionTypes";

export const showCourseCompleteMessage = () => {
    return (dispatch)=>{
        dispatch({
        type: SHOW_COURSE_COMPLETE_MESSAGE,
        })
    };
}

export const hideCourseCompleteMessage = () => {
    return (dispatch)=>{
        dispatch({
        type: HIDE_COURSE_COMPLETE_MESSAGE,
        })
    };
}

export const courseComplete = () => {
    return (dispatch)=>{
        dispatch({
        type: COURSE_COMPLETE,
        })
    };
}

export const resetCourseComplete = () => {
    return (dispatch)=>{
        dispatch({
        type: RESET_COURSE_COMPLETE,
        })
    };
}
