import { Platform } from 'react-native';

import { translate } from 'i18n/context/I18nContext';
import Permissions from './Permissions';

const VIDEO_CONSTRAINTS = {
  low: { width: { ideal: 320 }, height: { ideal: 320 } },
  mid: { width: { ideal: 480 }, height: { ideal: 480 } },
  high: { width: { ideal: 1280 }, height: { ideal: 1280 } },
};

class MediaManager {
  constructor() {
    this.cameras = new Map();
    this.microphones = new Map();
    this.speakers = new Map();
    this.uniqueDevices = new Map();
    this.facing = 'front';

    this.videoStream = undefined;
    this.audioStream = undefined;

    this.selectedAudioDeviceId = undefined;
    this.selectedVideoDeviceId = undefined;

    this.resolution = 'high';

    this.errors = {
      microphone: '',
      camera: '',
      speaker: '',
    };
  }

  async updateDevices() {
    // Reset the lists
    this.cameras = new Map();
    this.microphones = new Map();
    this.speakers = new Map();
    this.uniqueDevices = new Map();

    let availableCameras = [];
    let availableMicrophones = [];
    let availableSpeakers = [];

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      for (const device of devices) {
        if (device.deviceId === '') {
          console.warn('Empty device ID detected; this could be a permissions issue.');
          continue;  // Skip devices with empty device IDs
        }

        switch (device.kind) {
          case 'videoinput':
            this.cameras.set(device.deviceId, device);
            availableCameras.push(device);
            break;
          case 'audioinput':
            this.microphones.set(device.deviceId, device);
            availableMicrophones.push(device);
            break;
          case 'audiooutput':
            this.speakers.set(device.deviceId, device);
            availableSpeakers.push(device);
            break;
          default:
            console.warn('Unknown device type detected:', device.kind);
        }

        // Group by unique device groupId
        if (!this.uniqueDevices.has(device.groupId)) {
          this.uniqueDevices.set(device.groupId, []);
        }
        this.uniqueDevices.get(device.groupId).push(device);
      }

      return {
        cameras: availableCameras,
        microphones: availableMicrophones,
        speakers: availableSpeakers,
      };
    } catch (error) {
      console.error('Error enumerating devices:', error);
      return {
        cameras: [],
        microphones: [],
        speakers: [],
      };
    }
  }


  async getVideoStream() {
    if (!this.hasCamera()) {
      this.errors.camera = translate('mediaManager.noCam');
      throw new Error(translate('mediaManager.noCam'));
    }

    // Supports flipping camera on mobile / selecting camera on web
    if (
      (this.videoStream &&
        Platform.OS === 'web' &&
        !this.selectedVideoDeviceId) ||
      (this.videoStream && Platform.OS !== 'web')
    ) {
      const track = this.videoStream.getVideoTracks()[0];
      const state = track.readyState;
      if (this.videoStream.active && state === 'live') {
        return this.videoStream;
      }
    }

    const constraints = {
      video: {
        ...VIDEO_CONSTRAINTS[this.resolution],
        deviceId: this.selectedVideoDeviceId,
      },
    };
    if (this.hasMultipleDevices() && this.selectedVideoDeviceId) {
      constraints.video.deviceId = { exact: this.selectedVideoDeviceId };
    }

    if (Platform.OS !== 'web') {
      constraints.video.facingMode = { exact: this.facing };
    }

    try {
      this.videoStream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
      this.errors.camera = error;
    }

    return this.videoStream;
  }

  async disableVideoStream() {
    if (this.videoStream) {
      const track = this.videoStream.getVideoTracks()[0];
      track.stop();
      const state = track.readyState;
      if (!this.videoStream.active || state === 'ended') {
        this.videoStream = undefined;
      }
    }
  }

  async getAudioStream() {
    if (!this.hasMicrophone()) {
      this.errors.microphone = translate('mediaManager.noMic');
      throw new Error(translate('mediaManager.noMic'));
    }

    if (this.audioStream && !this.selectedAudioDeviceId) {
      const track = this.audioStream.getAudioTracks()[0];
      const state = track.readyState;
      if (this.audioStream.active && state === 'live') {
        return this.audioStream;
      }
    }

    try {
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: this.selectedAudioDeviceId,
        },
      });
    } catch (error) {
      this.errors.microphone = error;
    }

    return this.audioStream;
  }

  async disableAudioStream() {
    if (this.audioStream) {
      const track = this.audioStream.getAudioTracks()[0];
      track.stop();
      const state = track.readyState;
      if (!this.audioStream.active || state === 'ended') {
        this.audioStream = undefined;
      }
    }
  }

  hasMultipleDevices() {
    return this.uniqueDevices.size > 1;
  }

  hasMultipleCameras() {
    return this.cameras.size > 1 && this.uniqueDevices.size > 1;
  }

  hasMultipleMicrophones() {
    return this.microphones.size > 1 && this.uniqueDevices.size > 1;
  }

  hasCamera() {
    return this.cameras.size > 0;
  }

  hasMicrophone() {
    return this.microphones.size > 0;
  }

  canSwitchCamera() {
    return this.cameras.size > 1;
  }

  shouldMirror() {
    if (Platform.OS !== 'web') {
      return this.facing === 'front';
    } else {
      if (this.videoStream) {
        const track = this.videoStream.getVideoTracks()[0];
        if (/(back|rear)/i.test(track.label)) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
  }

  async switchCamera() {
    if (Platform.OS !== 'web') {
      const tracks = this.videoStream.getVideoTracks();
      const track = tracks[0];
      track._switchCamera();
      this.facing = this.facing === 'front' ? 'environment' : 'front';
    } else {
      const array = Array.from(this.cameras.keys());
      const len = array.length;
      let idx = array.indexOf(this.selectedVideoDeviceId);

      if (idx < len - 1) {
        idx++;
      } else {
        idx = 0;
      }
      this.selectedVideoDeviceId = array[idx];
    }
    return await this.getVideoStream();
  }

  async selectCamera(deviceId) {
    this.selectedVideoDeviceId = deviceId;
    return await this.getVideoStream();
  }

  async selectMicrophone(deviceId) {
    this.selectedAudioDeviceId = deviceId;
    return await this.getAudioStream();
  }

  async checkPermissions() {
    return await Permissions.checkPermissions();
  }

  getAudioStatus() {
    return Permissions.getAudioStatus();
  }

  getVideoStatus() {
    return Permissions.getVideoStatus();
  }

  getVideoError() {
    return this.errors.camera;
  }

  getAudioError() {
    return this.errors.microphone;
  }

  async requestMicPermissions() {
    return await Permissions.requestMicPermissions();
  }

  async requestCameraPermissions() {
    return await Permissions.requestCameraPermissions();
  }
}

const mediaManager = new MediaManager();

export default mediaManager;
