import { START_AI_ONETOONE_MEETING_LISTENING, START_AI_TEACHER_LISTENING, STOP_AI_ONETOONE_MEETING_LISTENING, STOP_AI_TEACHER_LISTENING } from "../actions/actionTypes"

export const initialState = {
    AITeacherListeningStatus: false,
    oneToOneMeetingStatus: false
}

const speechToTextService = (state = initialState, action) => {

    switch (action.type) {
        case START_AI_TEACHER_LISTENING:
            return { ...state, AITeacherListeningStatus: true }
        case STOP_AI_TEACHER_LISTENING:
            return { ...state, AITeacherListeningStatus: false }
        case START_AI_ONETOONE_MEETING_LISTENING:
            return { ...state, oneToOneMeetingStatus: true }
        case STOP_AI_ONETOONE_MEETING_LISTENING:
            return { ...state, oneToOneMeetingStatus: false }
        default:
            return state;
    }
}

export default speechToTextService;