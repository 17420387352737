import React, { useState, useEffect } from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet, Appearance } from 'react-native';

const ConfirmationDialog = ({ title, message, onConfirm, onCancel, confirmParam = 'Yes', cancelParam = 'No'}) => {
  const [colorScheme, setColorScheme] = useState(Appearance.getColorScheme());

  useEffect(() => {
    const subscription = Appearance.addChangeListener(({ colorScheme }) => {
      setColorScheme(colorScheme);
    });
    return () => { if (subscription) subscription.remove() };
  }, []);

  const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalView: {
      margin: 20,
      backgroundColor: colorScheme === 'dark' ? 'black' : 'white',
      borderRadius: 20,
      padding: 35,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      width:500,
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '100%',
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
      width: '40%',
    },
    buttonConfirm: {
      backgroundColor: '#C5E836',
    },
    buttonCancel: {
      backgroundColor: '#f44336',
    },
    modalTitle: {
      marginBottom: 15,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 18,
      color: colorScheme === 'dark' ? 'white' : 'black',
    },
    modalText: {
      marginBottom: 15,
      textAlign: 'center',
      color: colorScheme === 'dark' ? 'white' : 'black',
    },
    textStyle: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    textStyleConfirm: {
      color: 'black',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  });

  return (
    <Modal
      transparent={true}
      animationType="slide"
      visible={true}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>{title}</Text>
          <Text style={styles.modalText}>{message}</Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={[styles.button, styles.buttonConfirm]}
              onPress={onConfirm}
            >
              <Text style={styles.textStyleConfirm}>{confirmParam}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.button, styles.buttonCancel]}
              onPress={onCancel}
            >
              <Text style={styles.textStyle}>{cancelParam}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmationDialog;