const English = {
  actions: {
    cameraError: 'Camera Error - ',
    microphoneError: 'Microphone Error - ',
  },
  app: {
    necessary: '<Is this necessary?>',
    restart: 'Restart',
    unexpectedError: 'Unexpected error occurred',
    reported:
      'We have reported this to our team! Please try restarting the app.',
  },
  attendance: {
    copiedClipboard: 'Copied to clipboard!',
    shareLink: 'Share 3DMeet Link',
    waitingForUsers: 'Waiting for other users',
    joinMeeting: 'Join my 3Dmeet meeting!\n{{inviteURL}}',
  },
  attendanceContent: {
    recall: 'Recall',
    teleport: 'Teleport to',
  },
  avatarPreview: {
    join: 'Enter Course',
    joinMeeting: 'Customize Your Avatar',
    traySpacer:
      'Select an option in the sidebar to customize different parts of your avatar.',
    headSelfie: 'Take Selfie',
    headPicture: 'Choose Photo From File',
    headPreset: 'Preset Game Heads',
    headSave: 'Save',
    headCapture: 'Capture Photo',
    loadingWebcam: 'Connecting To Camera',
    loadingTime:
      'This update may take a minute. Thanks for your patience.\n\n[dismiss]',
    avatarUpdateProblem:
      'There was an issue updating your avatar. You have been given a default avatar appearance due to this issue. Please try your avatar update again. \n\n[dismiss]',
    imageFlashMessageTitle: 'Please note the following about your image:',
    imageFlashMessageDescription:
      '1) The face should be easily recognizable in the photo - i.e., the photo cannot be blurry.\n2) Photo should have uniform good lighting, without dark shadows or too bright a glare.\n3) The face should keep a neutral facial expression or slight smile but without opening their mouth. If teeth are visible on the input photo the results will be incorrect.\n4) The face should be looking straight into the camera without turning your neck or eyes.\n5) We advise removing any glasses as they can confuse the facial detection.\n\nWhen you are ready to proceed click below.\n\n[dismiss to continue]',
    updatesInProgress:
      'The game is processing your latest updates. Give it a moment to catch up before you make further changes.\n\n[dismiss]',
    nextBody: 'Next',
    previousBody: 'Back',
    saveAppearance: 'Save Avatar',
    startAppearance: 'Change Avatar',
  },
  cacheActions: {
    downloadWorldComplete: '{{worldName}} Download Complete',
    downloadError1: 'An error occurred while downloading {{level}} level.',
    downloadError2:
      'An error occurred while downloading {{level}} level. Please check your connection and try again.',
    downloadComplete: 'Download Complete',
    downloadInvalid: 'Download is invalid at: {{levelDownloadUrl}}',
    teleportWorld: 'Would you like to teleport to the {{worldName}} world?',
    teleport: 'Teleport',
  },
  connectionError: {
    failed: 'Connection Failed',
  },
  connectionFailureOverlay: {
    poorConnection: 'Poor connection detected',
  },
  controlAll: {
    allow: 'Allow',
    block: 'Block',
    controlAll: 'Control All',
    recallEveryone: 'Recall All',
  },
  controls: {
    micMuted: 'Your mic has been muted',
    videoDisabled: 'Your video has been disabled',
  },
  deviceConfig: {
    join: 'Enter Course',
  },
  deviceSelectionModal: {
    attSpeakerOff: 'Attempted to turn speakerphone off',
    attSpeakerOn: 'Attempted to turn speakerphone on',
    audio: 'Audio',
    speaker: 'Speaker',
    speakerOff: 'Turn Speakerphone Off',
    speakerOn: 'Turn Speakerphone On',
  },
  deviceSelectionModalWeb: {
    audioVideo: 'Audio & Video',
    cameraOff: 'Camera is off',
    noCameras: 'Could not detect any available cameras',
    noMics: 'Could not detect any available microphones',
    micOff: 'Microphone is off',
    audio: 'Audio',
    video: 'Video',
    microphone: 'Microphone',
    camera: 'Camera',
  },
  emotes: {
    emoteDesc: 'Emotes you can perform, like raise hand, sit, and even fly!',
  },
  errorHandler: {
    wrong: 'Something went wrong',
    tryAgain: 'Try again',
  },
  landing: {
    meetingDialog:
      'Ongoing meeting: {{lastMeetingId}}. Press to rejoin meeting, long press to clear.',
  },
  languageSwitcher: {
    chooseLanguage: 'Choose Language',
  },
  leaveMeetingOverlay: {
    confirm: 'Are you sure?',
    endForEveryone: 'End Meeting For Everyone',
    end: 'End',
    leave: 'Leave Meeting',
    endKickEveryone:
      'Ending the meeting for everyone will kick everyone out of the current meeting.',
  },
  linkActions: {
    invalidToken: 'Invalid Token',
    userNum: 'User #{{num}}',
  },
  linkActionsWeb: {
    clickForSupport: 'Invalid meeting link - click here for support.',
    userNum: 'User #{{num}}',
  },
  liveTranslation: {
    clearTranslations: 'Clear',
    hideOriginal: 'hide original',
    showOriginal: 'show original',
    liveTranslation: 'Chat Translation',
    refreshTranslations: 'Refresh',
  },
  mediaManager: {
    noCam: 'No camera devices',
    noMic: 'No microphone devices',
  },
  meeting: {
    navigation: 'Navigation',
    people: 'People',
    settings: 'Settings',
    viewboards: 'Viewboards',
    avatar: 'Avatar',
    bodyType: 'Body Type',
    messages: 'Chat',
  },
  messageBubble: {
    copyText: '📄 Copy Text',
    deleteMsg: '🗑 Delete Message',
  },
  messages: {
    chatDisabled: 'Chat is disabled',
    sendMsgAll: 'Send a message to everyone',
  },
  navigation: {
    downloadWorld: 'Download {{worldName}}',
    downloadWorldPrompt: 'Would you like to download the {{worldName}} world?',
    download: 'Download',
  },
  perspectiveSwitcher: {
    changePerspective: 'Change Perspective',
    first: 'First Person',
    overShoulder: 'Over Shoulder',
    third: 'Third Person',
  },
  positionalModeSettings: {
    fallOff: 'Fall off voice',
    positional: 'Positional Mode',
    bubbleArea: 'Show Bubble Area',
    bubble: 'bubble',
    world: 'world',
    global: 'global',
  },
  recorderWeb: {
    keepRecordingPrompt: 'Keep recording or not?',
    startRecording: 'Start recording',
    stopRecording: 'Stop recording',
    record: 'Record',
    stop: 'Stop',
  },
  recordingStatus: {
    meetingRecording: 'Meeting is being recorded',
  },
  recordMeeting: {
    record: 'Record',
  },
  refreshMeetingConfigsButton: {
    errorRefreshMeeting:
      'An error occurred while trying to refresh your meeting configs.',
    refreshConfigs: 'Refresh Meeting Configs',
    confirmreRefreshedConfigs: 'Your meeting configs were refreshed.',
  },
  broadcastAppearanceButton: {
    buttonText: 'Send Appearance',
    flashMessage:
      'You currently have avatar customizations being processed. Please give us a minute to update your Avatar before you broadcast your appearance.',
  },
  settingsDialogueOverlay: {
    openSettings: 'Open Settings',
    showInstructions: 'Show Instructions',
    cameraMicEnablePrompt:
      'You must enable microphone and camera permissions in app settings.',
    sitePermissionPrompt:
      'You must give this site permission to use the microphone and camera',
  },
  shared: {
    cancel: 'Cancel',
    close: 'Close',
  },
  shareMeetingModal: {
    copyJoinInfo: 'Copy joining info',
    joinMeeting: 'Join my 3DMeet meeting {{inviteURL}}',
    share: 'Share',
    shareInvite: 'Share invite',
    shareWithOthers:
      'Share this joining info with others you want in the meeting',
    firstOneHere: "You're the first one here",
  },
  translationSwitcher: {
    default: 'Target Language',
    en: 'English',
    zh: 'Chinese',
    fr: 'French',
    de: 'German',
    ru: 'Russian',
    es: 'Spanish',
  },
  viewboardAddInstanceContentButton: {
    shareYT: 'Share YouTube Video',
  },
  viewboardAddInstanceContentModal: {
    error: 'An error has occurred',
    enterLink: 'Enter link here',
    enterYTLink: 'Enter YouTube Video Link',
    errorContent: 'Error occurred while trying to add instance content',
    invalidLink: 'Invalid link provided',
    videoAdded: 'Your video was added successfully.',
    clear: 'Clear',
    submit: 'Submit',
  },
  viewboardContent: {
    contentSlideshow:
      'Content is a Slideshow, which can be made up of both images and videos',
    contentVideo: 'Content is a Video',
    contentImage: 'Content is an Image',
  },
  viewboardDashboard: {
    activeViewboard: 'Active Viewboard',
    activeViewboards: 'Active Viewboards',
    noActiveViewboard: 'No Active Viewboards',
  },
  viewboardSelect: {
    selectViewboard: 'Select Viewboard',
    viewboardDisplay: 'Viewboard {{num}}',
  },
  viewboardSelectContent: {
    noContent: 'No content is available',
    selectContent: 'Select Content',
  },
  viewboardShareButton: {
    shareViewboard: 'Share On Viewboard',
  },
  viewboardUploadContentButton: {
    errorUpload: 'An error occurred while trying to upload your file.',
    uploadContent: 'Upload Content',
    uploadSuccess: 'Your file was successfully uploaded.',
    meeting: 'meeting',
  },
  viewboardUploadContentButtonWeb: {
    errorUpload: 'An error occurred while trying to upload your file.',
    uploadContent: 'Upload Content',
    uploadSuccess: 'Your file was successfully uploaded.',
  },
  worldSettingsListItems: {
    disableVideos: 'Disable Videos',
    floatingName: 'Floating names',
    lowPower: 'Low Power Mode',
    commServerLabel: 'Server',
  },
};

export default English;
