import React, {useContext, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Switch, Text, View, StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {
  disableLowPowerMode,
  enableLowPowerMode,
  disableAllIncomingVideo,
  enableAllIncomingVideo,
} from 'store/meeting/actions/settings';
import {enableFloatingNames, disableFloatingNames} from 'store/world/actions';
import {DISABLED_MEETING_CONTROL_COLOR} from 'style/theme';
import {I18nContext} from 'i18n/context/I18nContext';
import PositionalModeSettings from './PositionalModeSettings';
import PerspectiveSwitcher from './PerspectiveSwitcher';
import LanguageSwitcher from 'i18n/components/LanguageSwitcher';
import DeviceSelectionModal from './DeviceSelectionModal';
import RefreshMeetingConfigsButton from './RefreshMeetingConfigsButton';
import BroadcastAppearanceButton from './BroadcastAppearanceButton';
import RecordMeeting from './RecordMeeting';
import {
  checkPermission,
  VOICE_CHANGE_POSITIONAL_MODE,
  VIDEO_CHANGE_POSITIONAL_MODE,
  MEETING_RECORD,
  ROOM_CONFIGS_REFRESH,
} from 'utils/permissions';
import {logEvent} from 'utils/firebase/analytics';
import {
  TOGGLE_LOW_POWER_MODE,
  TOGGLE_FLOATING_NAMES,
  TOGGLE_DISABLE_VIDEO,
} from 'utils/firebase/analytics.config';
import DeviceInfo from 'utils/communication/DeviceInfo';

const SUPPORTED_RECORDING_PLATFORMS = ['google-chrome', 'firefox'];

const WorldSettingsListItems = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const incomingVideoEnabled = useSelector(
    (state) => state.meeting.settings.incomingVideoEnabled,
  );
  const lowPowerMode = useSelector(
    (state) => state.meeting.settings.lowPowerMode,
  );
  const permissions = useSelector(
    (state) => state.communication.me.permissions,
  );
  const showFloatingNames = useSelector(
    (state) => state.world.showFloatingNames,
  );

  // const roomClient = useSelector(
  //   (state) => state.communication.control.roomClient,
  // );
  // const [commServer] = useState(roomClient.getCommunicationServer());

  const isRecordingSupported = () => {
    return SUPPORTED_RECORDING_PLATFORMS.includes(DeviceInfo.platform);
  };

  const toggleLowPower = async () => {
    if (lowPowerMode) {
      dispatch(disableLowPowerMode());
    } else {
      dispatch(enableLowPowerMode());
    }

    logEvent(TOGGLE_LOW_POWER_MODE, {
      low_power_mode_status: !lowPowerMode,
    });
  };

  const toggleShowFloatingNames = async () => {
    if (showFloatingNames) {
      dispatch(disableFloatingNames());
    } else {
      dispatch(enableFloatingNames());
    }

    logEvent(TOGGLE_FLOATING_NAMES, {
      floating_names_status: !showFloatingNames,
    });
  };

  const toggleDisableVideo = async () => {
    if (incomingVideoEnabled) {
      dispatch(disableAllIncomingVideo());
    } else {
      dispatch(enableAllIncomingVideo());
    }

    logEvent(TOGGLE_DISABLE_VIDEO, {
      disable_video_status: !incomingVideoEnabled,
    });
  };

  const lowPowerIconColor = {
    color: lowPowerMode ? '#008000' : '#808080',
  };

  const floatingNameIconColor = {
    color: showFloatingNames ? '#008000' : '#808080',
  };

  const videoDisabled = !incomingVideoEnabled;

  const disableVideoIconColor = {
    color: videoDisabled ? DISABLED_MEETING_CONTROL_COLOR : '#808080',
  };

  const commServerIconColor = {
    color: '#808080',
  };

  return (
    <View style={styles.container}>
      <DeviceSelectionModal />
      {checkPermission(ROOM_CONFIGS_REFRESH, permissions) && (
        <RefreshMeetingConfigsButton />
      )}
      <BroadcastAppearanceButton />
      <View>
        <View style={styles.item}>
          <View style={styles.display}>
            <Icon
              type="MaterialCommunityIcons"
              style={[styles.icon, lowPowerIconColor]}
              name="battery-plus"
            />
            <Text style={styles.text}>
              {translate('worldSettingsListItems.lowPower')}
            </Text>
          </View>
          <Switch onValueChange={() => toggleLowPower()} value={lowPowerMode} />
        </View>
      </View>
      <View>
        <View style={styles.item}>
          <View style={styles.display}>
            <Icon
              type="MaterialCommunityIcons"
              style={[styles.icon, floatingNameIconColor]}
              name="account-question"
            />
            <Text style={styles.text}>
              {translate('worldSettingsListItems.floatingName')}
            </Text>
          </View>
          <Switch
            onValueChange={() => toggleShowFloatingNames()}
            value={showFloatingNames}
          />
        </View>
      </View>
      <View>
        <View style={styles.item}>
          <View style={styles.display}>
            <Icon
              type="MaterialCommunityIcons"
              style={[styles.icon, disableVideoIconColor]}
              name="video-off"
            />
            <Text style={styles.text}>
              {translate('worldSettingsListItems.disableVideos')}
            </Text>
          </View>
          <Switch
            onValueChange={() => toggleDisableVideo()}
            value={videoDisabled}
          />
        </View>
      </View>

      <PerspectiveSwitcher />
      <LanguageSwitcher />
      {checkPermission(VOICE_CHANGE_POSITIONAL_MODE, permissions) ||
      checkPermission(VIDEO_CHANGE_POSITIONAL_MODE, permissions) ? (
        <PositionalModeSettings />
      ) : null}
      {isRecordingSupported() &&
      checkPermission(MEETING_RECORD, permissions) ? (
        <RecordMeeting />
      ) : null}
      <View>
        <View style={styles.item}>
          <View style={styles.display}>
            <Icon
              type="MaterialCommunityIcons"
              style={[styles.icon, commServerIconColor]}
              name="server"
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  item: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 5,
    marginTop: 10,
  },
  display: {
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 18,
    color: 'white',
  },
  icon: {
    fontSize: 28,
    marginRight: 5,
  },
});

export default WorldSettingsListItems;
