import { useDataChannel, useRemoteParticipants, useTracks } from '@livekit/components-react';
import { RoomEvent, Track } from 'livekit-client';
import React from 'react'
import { useCallback } from 'react';
import { TranscriptionTile } from './TranscriptionTile';
import { useMemo } from 'react';

function LiveKitSDK() {
    const tracks = useTracks();
    const participants = useRemoteParticipants({
        updateOnlyOn: [RoomEvent.ParticipantMetadataChanged],
      });
      const agentParticipant = participants.find((p) => p.isAgent);
      const isAgentConnected = agentParticipant !== undefined;
    let agentAudioTrack;

    const aat = tracks.find(
        (trackRef) =>
            trackRef.publication.kind === Track.Kind.Audio &&
            trackRef.participant.isAgent
    );
    if (aat) {
        agentAudioTrack = aat;
    } else if (agentParticipant) {
        agentAudioTrack = {
            participant: agentParticipant,
            source: Track.Source.Microphone,
        };
    }
    const chatTileContent = useMemo(() => {
        if (agentAudioTrack) {
            return (
                <TranscriptionTile
                    agentAudioTrack={agentAudioTrack}
                    accentColor={'#FF0000'}
                />
            );
        }
        return <></>;
    }, [agentAudioTrack]);

    const onDataReceived = useCallback(
        (msg) => {
            console.log('onDataReceived', msg);

            //   if (msg.topic === "transcription") {
            //     const decoded = JSON.parse(
            //       new TextDecoder("utf-8").decode(msg.payload)
            //     );
            //     let timestamp = new Date().getTime();
            //     if ("timestamp" in decoded && decoded.timestamp > 0) {
            //       timestamp = decoded.timestamp;
            //     }
            //     setTranscripts([
            //       ...transcripts,
            //       {
            //         name: "You",
            //         message: decoded.text,
            //         timestamp: timestamp,
            //         isSelf: true,
            //       },
            //     ]);
            //   }
        },
        []
    );
    useDataChannel((e) => {
        console.log('useDataChannel', e);

    });
    return (
        <>
            {chatTileContent}
        </>
    )
}

export default LiveKitSDK