// src/webhookService.js

import axios from 'axios';

const WEBHOOK_URL = 'https://chat.fluentworlds.com:6677/webhook';

/**
 * Sends data to the webhook.
 * @param {Object} payload - The data to send in the webhook request.
 * @returns {Promise} Axios Promise representing the result of the HTTP request.
 */
export const sendWebhookData = async (payload) => {
  try {
    const response = await axios.post(WEBHOOK_URL, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error sending data to the webhook:', error);
  }
};
