import * as types from './actionTypes';

export const joinMeeting = (status) => {
  return {
    type: types.JOIN_MEETING,
    payload: status,
  };
};

export const setAvatarURL = (url) => {
  return {
    type: types.SET_AVATAR_URL,
    payload: url,
  };
};
