import {combineReducers} from 'redux';
import settings from './settings';
import attendance from './attendance';
import properties from './properties';
import recording from './recording';
import meeting from './meeting';

const reducers = combineReducers({
  settings,
  attendance,
  properties,
  recording,
  meeting,
});

export default reducers;
