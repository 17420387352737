import {Platform} from 'react-native';
import Orientation from 'react-native-orientation-locker';
import {isTablet} from 'react-native-device-info';
import {v4 as uuidv4} from 'uuid';

import {clearWorld} from 'store/world/actions';
import {clearViewboards} from 'store/viewboard';
import {clearMessages} from 'store/communication/actions/chatActions';

import {updateAvailableDevices} from 'store/media';
import RoomClient from 'utils/communication/RoomClient';
import DeviceInfo from 'utils/communication/DeviceInfo';
import {VerifyAndParseToken} from 'utils/communication/Token';
import {logEvent} from 'utils/firebase/analytics';
import {
  JOIN_MEETING,
  LEAVE_MEETING,
  END_MEETING_FOR_ALL,
} from 'utils/firebase/analytics.config';
import PlatformWorld from 'components/World/PlatformWorld';
import {downloadLevel} from 'store/app/cacheActions';

export const joinRoom = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {token} = state.meeting.properties;

    // Verify token (reject/return to previous state if invalid)
    const verifiedToken = VerifyAndParseToken(token);
    if (verifiedToken.hasOwnProperty('error')) {
      console.error(verifiedToken.error);
    }

    console.log('verifiedToken from joinRoom:', verifiedToken);
    console.log('worldToLoad from joinRoom:', verifiedToken.worldToLoad);

    // Check if worldToLoad is available
    console.log('availableLevels from joinRoom:', state.app.availableLevels);

    if (Platform.OS !== 'web') {
      if (state.app.availableLevels[verifiedToken.worldToLoad]) {
        console.log('World is available:', verifiedToken.worldToLoad);
      } else {
        console.log('World is not available:', verifiedToken.worldToLoad);
        downloadLevel(verifiedToken.worldToLoad);
      }
    } else {
      console.log('Platform is web, skipping world download.');
    }

    // Set peerId and roomId from token
    const {peerId, roomId, communicationServer} = verifiedToken;

    // Display name should be received from server since it would come from the userInfoURL request (which needs to be signed)
    let displayName = peerId;
    const handler = Platform.OS === 'web' ? undefined : 'ReactNative';
    const useSimulcast = true;
    const useSharingSimulcast = true;
    const forceTcp = false;
    const produce = true;
    const consume = true;
    const forceH264 = false;
    const forceVP9 = false;
    const svc = undefined;
    const datachannel = true;

    let device = DeviceInfo;

    const callId = uuidv4();

    const roomClient = new RoomClient({
      token,
      callId,
      roomId,
      peerId,
      displayName,
      device,
      handlerName: handler,
      useSimulcast,
      useSharingSimulcast,
      forceTcp,
      produce,
      consume,
      forceH264,
      forceVP9,
      svc,
      datachannel,
      communicationServer,
    });

    dispatch(updateAvailableDevices());

    await logEvent(JOIN_MEETING, {meeting_id: roomId});
    roomClient.join();

    dispatch({
      type: 'JOIN_ROOM',
      payload: {roomClient},
    });

    // if current device is not a tablet nor android, unlock all orientations on join meeting
    if (!isTablet() && Platform !== 'android') {
      Orientation.unlockAllOrientations();
    }
  };
};

export const leaveRoom = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    let isMeetingValid;
    if (state.meeting.properties.isMeetingValid) {
      isMeetingValid = 'true';
    } else {
      isMeetingValid = 'false';
    }

    await logEvent(LEAVE_MEETING, {
      meeting_id: roomClient.meetingId,
      is_meeting_valid: isMeetingValid,
      meeting_max_number_participants:
        state.meeting.properties.meetingMaxNumberParticipants,
    });
    await roomClient.close();

    if (Platform.OS !== 'web') {
      console.log("Leave meeting so close media player.")
      PlatformWorld.closeMediaPlayer();
    }

    dispatch(clearMessages());
    dispatch(clearViewboards());
    dispatch(clearWorld());
    dispatch(clear());

    // if current device is not a tablet, lock to portrait on leave meeting
    if (!isTablet()) {
      Orientation.lockToPortrait();
    }
  };
};

export const endMeetingForAll = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    await logEvent(END_MEETING_FOR_ALL, {
      meeting_id: roomClient.meetingId,
    });
    await roomClient.endMeetingForAll();

    if (Platform.OS !== 'web') {
      console.log("End meeting so close media player.")
      PlatformWorld.closeMediaPlayer();
    }
  };
};

export const clear = () => {
  return {
    type: 'CLEAR_ROOM',
  };
};

export const sendWorldMessage = (message) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.sendWorldMessage(message);
  };
};

export const syncWorldPeerState = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.syncAllPeerState();
  };
};

export const sendAppearance = (appearance) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.setAppearance(appearance);
  };
};

export const sendLocation = (location) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.setLocation(location);
  };
};

export const syncPeerSate = (peerId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.syncPeerState(peerId);
  };
};
