import fhead from './icons/female/face.png';
import fshoes from './icons/female/shoe.png';
import fbottom from './icons/female/skirt.png';
import ftop from './icons/female/wshirt.png';
import ffull from './icons/female/dress.png';

import mbelt from './icons/male/belt.png';
import mhead from './icons/male/mface.png';
import mtop from './icons/male/mshirt.png';
import mshoes from './icons/male/mshoes.png';
import mbottom from './icons/male/pants.png';
import mties from './icons/male/tie.png';

const images = {
  female: {
    head: fhead,
    shoes: fshoes,
    bottom: fbottom,
    top: ftop,
    dress: ffull,
  },
  male: {
    belts: mbelt,
    head: mhead,
    top: mtop,
    shoes: mshoes,
    bottom: mbottom,
    ties: mties,
  },
  boy: {
    belts: mbelt,
    head: mhead,
    top: mtop,
    shoes: mshoes,
    bottom: mbottom,
    ties: mties,
  },
  girl: {
    head: fhead,
    shoes: fshoes,
    bottom: fbottom,
    top: ftop,
    dress: ffull,
  },
};

export default images;
