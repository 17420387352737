// src/hooks/useFetchToken.js
import { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const useFetchTokenLiveKit = () => {
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const getToken = async ({ teacherName, courseName, voice_id, courseId, userId }) => {
        setLoading(true);
        try {
            const response = await axios.post(
                'https://client-backend.ivault.ngrok.app/get_token',
                {
                    instructor: teacherName,
                    course: courseName,
                    metadata: JSON.stringify({
                        voice_id: voice_id,
                        institution: 'quiklearn',
                        courseId, userId,
                        session_id: uuidv4()
                    }),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setToken(response.data.token); // Adjust according to the response structure
            setError(null);
        } catch (err) {
            console.error('getToken', err);

            setError(err.message || 'An error occurred');
            setToken(null);
        } finally {
            setLoading(false);
        }
    };

    return { token, error, loading, getToken };
};

export default useFetchTokenLiveKit;
