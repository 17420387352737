import {
    useLocalParticipant, useTrackTranscription
} from "@livekit/components-react"
import World from "components/World";
import { Track } from "livekit-client";
import { useEffect, useState } from "react";

export function TranscriptionTile({ agentAudioTrack }) {
    const agentMessages = useTrackTranscription(agentAudioTrack);
    const localParticipant = useLocalParticipant();
    const localMessages = useTrackTranscription({
        publication: localParticipant.microphoneTrack,
        source: Track.Source.Microphone,
        participant: localParticipant.localParticipant,
    });
    // Process agent messages
    useEffect(() => {
        agentMessages.segments.forEach((s) => {
            console.log("Sending Agent Transcript:", {
                id: s.id,
                text: s.text,
                final: s.final,
                participant: "tutor",
                timestamp: s.lastReceivedTime
            });

            World.runCommand(`livekit_final_response ${s.id} ${s.text.replace(/ /g, '_')} ${s.final} tutor`);
        });
    }, [agentMessages.segments]);

    // Process local participant (customer) messages
    useEffect(() => {
        localMessages.segments.forEach((s) => {
            console.log("Sending Local Transcript:", {
                id: s.id,
                text: s.text,
                final: s.final,
                participant: "player",
                timestamp: s.lastReceivedTime
            });

            World.runCommand(`livekit_final_response ${s.id} ${s.text.replace(/ /g, '_')} ${s.final} player`);
        });
    }, [localMessages.segments]);


    return null;
}