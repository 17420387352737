import React, {useState, useContext} from 'react';
import {Text, View, StyleSheet, Platform} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {I18nContext, getLanguageCode} from 'i18n/context/I18nContext';
import {
  LANGUAGE_ENGLISH_DESC,
  LANGUAGE_SPANISH_DESC,
  LANGUAGE_ENGLISH_CODE,
  LANGUAGE_SPANISH_CODE,
} from 'i18n/utils';

const LanguageSwitcher = () => {
  const {translate, changeLanguage} = useContext(I18nContext);
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguageCode());

  const languageIconColor = {color: '#3457D5'};

  const updateSelectedLanguage = (languageCode) => {
    setSelectedLanguage(languageCode);
    changeLanguage(languageCode);
  };

  return (
    <View style={styles.container}>
      <View style={styles.pickerContainer}>
        <View style={styles.pickerHeader}>
          <Icon
            type="MaterialCommunityIcons"
            style={[styles.icon, languageIconColor]}
            name="closed-caption"
          />
          <Text style={styles.settingsText}>
            {translate('languageSwitcher.chooseLanguage')}
          </Text>
        </View>
        <Picker
          selectedValue={selectedLanguage}
          style={styles.picker}
          onValueChange={(itemValue) => updateSelectedLanguage(itemValue)}>
          <Picker.Item
            label={LANGUAGE_ENGLISH_DESC}
            value={LANGUAGE_ENGLISH_CODE}
            color='white'
          />
          <Picker.Item
            label={LANGUAGE_SPANISH_DESC}
            value={LANGUAGE_SPANISH_CODE}
            color='white'
          />
        </Picker>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  pickerContainer: {
    flexDirection: 'column',
  },
  pickerHeader: {
    flexDirection: 'row',
  },
  picker: {
    marginTop: Platform.OS === 'ios' ? -40 : 0,
  },
  settingsText: {
    fontSize: 18,
    marginTop: 5,
    color: 'white',
  },
  icon: {
    fontSize: 28,
    margin: 5,
  },
});

export default LanguageSwitcher;
