import {Linking, Platform} from 'react-native';
import {showMessage} from 'react-native-flash-message';

import * as types from './actionTypes';
import MediaManager from 'utils/communication/MediaManager';
import * as Permissions from 'utils/communication/Permissions';
import {logEvent} from 'utils/firebase/analytics';
import {
  ENABLE_SCREENSHARE_EVENT,
  ENABLE_CAMERA_EVENT,
  SELECT_CAMERA_DEVICE,
  SELECT_MICROPHONE_DEVICE,
} from 'utils/firebase/analytics.config';

export const disableMic = () => {
  return async (dispatch, getState) => {
    await dispatch({
      type: types.SET_MICROPHONE_STREAM,
      payload: {stream: undefined},
    });
    await MediaManager.disableAudioStream();
    return await dispatch({type: types.DISABLE_MIC});
  };
};

export const disableCamera = () => {
  return async (dispatch, getState) => {
    await dispatch({
      type: types.SET_CAMERA_STREAM,
      payload: {stream: undefined},
    });
    await MediaManager.disableVideoStream();
    return await dispatch({type: types.DISABLE_CAMERA});
  };
};

export const enableWebboard = (webboardUrl) => {
  //console.error("Usman webview inside enable webboard "+ webboardUrl);
  return (dispatch)=>{
      dispatch({
      type: types.ENABLE_WEBBOARD,
      payload : webboardUrl,
      })
  };
}

export const disableWebboard = () => {
  return (dispatch)=>{
      dispatch({
      type: types.DISABLE_WEBBOARD,
      payload : '',
      })
  };
}

const setPermissions = async (dispatch, micPermissions, cameraPermissions) => {
  await dispatch({
    type: types.SET_MIC_PERMISSIONS,
    payload: {micPermissions},
  });
  return await dispatch({
    type: types.SET_CAMERA_PERMISSIONS,
    payload: {cameraPermissions},
  });
};

export const setAvailableDevices = (availableDevices) => {
  return {
    type: types.SET_AVAILABLE_DEVICES,
    payload: availableDevices,
  };
};

export const setSelectedMicrophoneDeviceId = (selectedMicrophoneDeviceId) => {
  return {
    type: types.SET_SELECTED_MICROPHONE_DEVICE_ID,
    payload: selectedMicrophoneDeviceId,
  };
};

export const setSelectedCameraDeviceId = (selectedCameraDeviceId) => {
  return {
    type: types.SET_SELECTED_CAMERA_DEVICE_ID,
    payload: selectedCameraDeviceId,
  };
};

export const updateAvailableDevices = () => {
  return async (dispatch, getState) => {
    const availableDevices = await MediaManager.updateDevices();

    return dispatch(setAvailableDevices(availableDevices));
  };
};

export const checkPermissions = () => {
  return async (dispatch, getState) => {
    const state = getState();
    let {micPermissions, cameraPermissions} = state.media;
    await MediaManager.checkPermissions();
    const nextMicPermissions = MediaManager.getAudioStatus();
    const nextCameraPermissions = MediaManager.getVideoStatus();
    if (
      micPermissions !== nextMicPermissions ||
      cameraPermissions !== nextCameraPermissions
    ) {
      await MediaManager.updateDevices();
    }
    return await setPermissions(
      dispatch,
      nextMicPermissions,
      nextCameraPermissions,
    );
  };
};

export const enableMic = () => {
  return async (dispatch, getState) => {
    await dispatch(checkPermissions());
    let micPermissions = MediaManager.getAudioStatus();
    if (micPermissions === Permissions.BLOCKED) {
      return await dispatch({
        type: types.SET_REQUESTED_BLOCKED_PERMISSION,
        payload: {requested: true},
      });
    } else if (micPermissions === Permissions.UNKNOWN) {
      await MediaManager.requestMicPermissions();
      await dispatch(checkPermissions());
      micPermissions = MediaManager.getAudioStatus();
    }

    if (micPermissions === Permissions.GRANTED) {
      const stream = await MediaManager.getAudioStream();
      if (stream === null || stream === undefined) {
        const errorMessage = `Microphone Error - ${MediaManager.getAudioError()}`;
        showMessage({
          message: errorMessage,
          hideStatusBar: Platform.OS === 'android' ? true : false,
          type: 'danger',
          icon: 'auto',
        });
        return;
      }
      await dispatch({type: types.SET_MICROPHONE_STREAM, payload: {stream}});
      await dispatch(updateAvailableDevices());
      return await dispatch({type: types.ENABLE_MIC});
    }
  };
};

export const enableCamera = () => {
  return async (dispatch, getState) => {
    await dispatch(checkPermissions());
    let cameraPermissions = MediaManager.getVideoStatus();
    if (cameraPermissions === Permissions.BLOCKED) {
      return await dispatch({
        type: types.SET_REQUESTED_BLOCKED_PERMISSION,
        payload: {requested: true},
      });
    } else if (cameraPermissions === Permissions.UNKNOWN) {
      await MediaManager.requestCameraPermissions();
      await dispatch(checkPermissions());
      cameraPermissions = MediaManager.getVideoStatus();
    }

    if (cameraPermissions === Permissions.GRANTED) {
      const stream = await MediaManager.getVideoStream();
      if (stream === null || stream === undefined) {
        const errorMessage = `Camera Error - ${MediaManager.getVideoError()}`;
        showMessage({
          message: errorMessage,
          hideStatusBar: Platform.OS === 'android' ? true : false,
          type: 'danger',
          icon: 'auto',
        });
        return;
      }
      await dispatch({type: types.SET_CAMERA_STREAM, payload: {stream}});
      await dispatch({type: types.ENABLE_CAMERA});
      await dispatch(updateAvailableDevices());
      const mirrorCamera = MediaManager.shouldMirror();

      const state = getState();
      const {roomClient} = state.communication.control;
      await logEvent(ENABLE_CAMERA_EVENT, {
        meeting_id:
          roomClient && roomClient.meetingId ? roomClient.meetingId : undefined,
      });

      await dispatch({
        type: types.SET_MIRROR_CAMERA,
        payload: {mirrorCamera},
      });
      return await dispatch({
        type: types.SET_CAN_SWITCH_CAMERA,
        payload: {canSwitchCamera: MediaManager.canSwitchCamera()},
      });
    }
  };
};

export const disableScreenShare = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    await dispatch({type: types.DISABLE_SCREEN_SHARE});

    await roomClient.disableShare();
  };
};

export const enableScreenShare = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    await dispatch({type: types.ENABLE_SCREEN_SHARE});

    await dispatch({
      type: types.SET_MIRROR_CAMERA,
      payload: {mirrorCamera: false},
    });

    await roomClient.enableShare();

    await logEvent(ENABLE_SCREENSHARE_EVENT, {
      meeting_id: roomClient.meetingId,
    });
  };
};

export const switchCamera = () => {
  return async (dispatch, getState) => {
    const stream = await MediaManager.switchCamera();
    if (stream === null || stream === undefined) {
      console.error('Could not switch camera');
      return;
    }

    await dispatch({type: types.SET_CAMERA_STREAM, payload: {stream}});
    const mirrorCamera = MediaManager.shouldMirror();
    return await dispatch({
      type: types.SET_MIRROR_CAMERA,
      payload: {mirrorCamera},
    });
  };
};

export const selectCamera = (cameraDeviceId) => {
  return async (dispatch, getState) => {
    const state = getState();

    const stream = await MediaManager.selectCamera(cameraDeviceId);
    if (stream === null || stream === undefined) {
      console.error('Could not select camera ', cameraDeviceId);
      return;
    }

    if (state.media.cameraEnabled) {
      await dispatch({type: types.SET_CAMERA_STREAM, payload: {stream}});
    }
    const mirrorCamera = MediaManager.shouldMirror();

    const {roomClient} = state.communication.control;
    await logEvent(SELECT_CAMERA_DEVICE, {
      meeting_id: roomClient.meetingId,
    });

    return await dispatch({
      type: types.SET_MIRROR_CAMERA,
      payload: {mirrorCamera},
    });
  };
};

export const selectMicrophone = (microphoneDeviceId) => {
  return async (dispatch, getState) => {
    const state = getState();

    const stream = await MediaManager.selectMicrophone(microphoneDeviceId);
    if (stream === null || stream === undefined) {
      console.error('Could not select microphone ', microphoneDeviceId);
      return;
    }

    if (state.media.micEnabled) {
      await dispatch({type: types.SET_MICROPHONE_STREAM, payload: {stream}});
    }

    const {roomClient} = state.communication.control;
    await logEvent(SELECT_MICROPHONE_DEVICE, {
      meeting_id: roomClient.meetingId,
    });
  };
};

export const openSettings = () => {
  return async (dispatch, getState) => {
    if (Platform.OS === 'web') {
      window.open(
        'https://3dmeetpublic.s3.us-west-2.amazonaws.com/help/micpermissions.html',
        '_blank',
      );
    } else {
      await Linking.openSettings();
    }

    return await dispatch({
      type: types.SET_REQUESTED_BLOCKED_PERMISSION,
      payload: {requested: false},
    });
  };
};

export const closeSettingsDialogue = () => {
  return {
    type: types.SET_REQUESTED_BLOCKED_PERMISSION,
    payload: {requested: false},
  };
};
