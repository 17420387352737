import * as types from '../actions/actionTypes';

export const initialState = {
  joinMeetingStatus: false,
  avatarUrlValue : "",
};

const updateState = (state = initialState, action) => {
  switch (action.type) {
    case types.JOIN_MEETING: {
      return {...state, joinMeetingStatus: action.payload};
    }
    case types.SET_AVATAR_URL: {
      return {...state, avatarUrlValue: action.payload};
    }
    default:
      return state;
  }
};

export default updateState;
