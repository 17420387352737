import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import base64 from 'react-native-base64';
import { showMessage } from 'react-native-flash-message';

import { I18nContext } from 'i18n/context/I18nContext';
import World from 'components/World';
import Appearances from 'utils/Appearances';
import { logEvent } from 'utils/firebase/analytics';
import {
  getResourceIDFromLocation,
  encodeForHTML,
  deepEqual,
} from 'utils/helpers';
import {
  updateTextOverlay,
  removeTextOverlay,
  clearTextOverlays,
  setLabelDisplay,
  clearLevelLoading,
} from 'store/world/actions';
import {
  syncWorldPeerState,
  sendWorldMessage,
  syncPeerSate,
  sendAppearance,
  sendLocation,
} from 'store/communication/actions/controlActions';
import {
  setAvatarLoading,
  setAppearance,
  setAppearanceInStorage,
  setAsdkId,
  forceBroadcast,
} from 'store/appearance';
import { setMeTransform } from 'store/communication/actions/stateActions';
import { clearViewboards, registerViewboards } from 'store/viewboard';
import {
  shouldUpdateTransformReduxState,
  shouldUpdateTransformUnity,
  convertMessageToTransform,
} from 'utils/World/Transform';
import worldStyle from 'style/3d';
import { LEVEL_LOAD } from 'utils/firebase/analytics.config';
import getRemoteConfigValue from 'utils/firebase/remoteConfig';
import { Platform } from 'react-native';
function getAddressablesPlatformName() {
  switch (Platform.OS) {
    case 'android':
      return 'Android';
    case 'ios':
      return 'iOS';
    case 'web':
      return 'WebGL';
    default:
      console.warn('Unexpected OS found: ', Platform.OS);
      return '';
  }
}
const MeetingWorld = () => {
  const [firstLoad, setFirstLoad] = useState(true);
  const dispatch = useDispatch();
  const location = useSelector((state) => state.world.location);
  const teleportToPeer = useSelector((state) => state.world.teleportToPeer);
  const showFloatingNames = useSelector(
    (state) => state.world.showFloatingNames,
  );
  const loadStarted = useSelector((state) => state.world.loadStarted);

  const lowPowerMode = useSelector(
    (state) => state.meeting.settings.lowPowerMode,
  );
  const meTransformData = useSelector(
    (state) => state.communication.me.transform,
  );
  const peers = useSelector((state) => state.communication.peers);
  const { translate } = useContext(I18nContext);

  const [floatingNames, setFloatingNames] = useState(showFloatingNames);
  useEffect(() => {
    setFloatingNames(showFloatingNames);
  }, [showFloatingNames]);

  const forceAppearanceBroadcast = useSelector(
    (state) => state.appearance.forceBroadcast,
  );
  useEffect(() => {
    if (forceAppearanceBroadcast) {
      World.runCommand('save_appearance');
      dispatch(forceBroadcast(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceAppearanceBroadcast]);

  const locations = useSelector((state) => state.meeting.properties.locations);
  const [locationResourceID, setLocationResourceID] = useState(
    getResourceIDFromLocation(location, locations),
  );
  useEffect(() => {
    if (location) {
      setLocationResourceID(getResourceIDFromLocation(location, locations));
    }
  }, [location, locations]);

  const looks = useSelector((state) => state.appearance.looks);
  const body = useSelector((state) => state.appearance.body);
  const look = looks[body];
  const asdkId = useSelector((state) => state.appearance.asdkId);
  const isFullBody = useSelector((state) => state.appearance.isFullBody);

  const AITeacherToLoad = useSelector((state) => state.app.courseTokenValues.AITeacherToLoad);
  const courseContentUrl = useSelector((state) => state.app.courseTokenValues.courseContentUrl);
  const courseProgress = useSelector((state) => state.app.courseTokenValues.courseProgress);
  const userId = useSelector((state) => state.app.courseTokenValues.userId);
  const collectEmails = useSelector((state) => state.app.courseTokenValues.collectEmails);

  const onLoadComplete = () => {
    setFirstLoad(true);
    dispatch(clearTextOverlays());
    const addressablesBaseUrl = getRemoteConfigValue(
      'tutors_addressables_catalog_base_url',
    ).asString();
    const addressablesCatalog = `${addressablesBaseUrl}${getAddressablesPlatformName()}/catalog_view.json`;

    World.runCommand(
      `initialize_avatar_system ${addressablesCatalog}`,
    );
    console.log('initialize_avatar_system', `initialize_avatar_system ${addressablesCatalog}`);

    World.runCommand('set_logger true 2');
    World.runCommand('set_overlay_z_index_delta 10000000');
    World.runCommand('set_overlay_distance_delta 0.0005');
    dispatch(sendLocation(location));
    // set the loading views
    dispatch(setAvatarLoading(true));
    if (teleportToPeer) {
      World.runCommand(`teleport_user ${teleportToPeer}`);
    }

    console.log('AITeacherToLoad from MeetingWorld:', AITeacherToLoad);
    console.log('courseContentUrl from MeetingWorld:', courseContentUrl);
    console.log('courseProgress from MeetingWorld:', courseProgress);

    const AITeacherToLoadWithUnderscores = AITeacherToLoad.replace(/\s+/g, '_');

    console.log('AITeacherToLoadNoSpaces in onUnityReadyToLoadLevel:', AITeacherToLoadWithUnderscores);

    World.runCommand(`setup_ai_tutor ${courseContentUrl} ${AITeacherToLoadWithUnderscores} ${courseProgress} ${userId} ${collectEmails}`);

    dispatch(clearViewboards());
    dispatch(registerViewboards(locationResourceID));
    if (loadStarted !== -1) {
      logEvent(LEVEL_LOAD, {
        level: location,
        duration: Date.now() - loadStarted,
      });
      dispatch(clearLevelLoading());
    }

    // sync peer state with all other existing peers
    dispatch(syncWorldPeerState());
  };

  const onLegacyFromWorld = (message) => {
    const { route, payload } = message;

    if (route === 'e') {
      dispatch(sendAppearance(payload));
    } else if (route === 't') {
      handleOverlayMessage(payload);
    } else if (route === 'n') {
      handleLabelMessage(payload);
    } else if (route === 'p') {
      const player_variables = payload.split('|');
      switch (player_variables[0]) {
        case 'rpmReady':
          console.log("Usman RPM ready recieved");
          dispatch(setAvatarLoading(false));
          break;
        case 'updatedAppearance':
          // player var for knowing when unity has finished updating an avatar.
          // process the updated appearance
          const saveToLocalStorage = false;
          const appearance = player_variables[1];
          // save new apperance data to redux store (but not to local storage yet)
          processAppearanceFromUnity(appearance, saveToLocalStorage);
          // broadcast appearance to others in game ONLY if it's their first load into the game
          if (firstLoad) {
            setFirstLoad(false);
            Appearances.broadcastAppearance(body, looks);
          }
          break;
        case 'asdkPlayerId':
          // player var for knowing the users asdkId from unity when they first start customizing.
          const newAsdkId = player_variables[1];
          saveUnityAsdkId(newAsdkId);
          // process the newAsdkId
          break;
        default:
          console.warn(
            'Unrecognized local player variable: ',
            player_variables[0],
          );
      }
    } else {
      dispatch(sendWorldMessage(message));
    }

    if (route === 'd') {
      // REQUEST REMOTE USER APPEARANCE
      dispatch(syncPeerSate(payload));
    }
  };

  const onTransformFromWorld = (message) => {
    const newTransformData = convertMessageToTransform(message?.payload);
    if (
      shouldUpdateTransformReduxState({
        oldTransformData: meTransformData,
        newTransformData,
      })
    ) {
      dispatch(setMeTransform(message));
    }

    if (
      shouldUpdateTransformUnity({
        oldTransformData: meTransformData,
        newTransformData,
      })
    ) {
      dispatch(sendWorldMessage(message));
    }
  };

  const getLabelInfo = (label) => {
    if (!label || label === '') {
      return null;
    }

    const sanitizedLabel = label.replace(/_([0-9]+)?/g, '');

    for (const loc of locations) {
      if (loc.id === location) {
        const locLabels = loc.labels;
        let info = null;
        if (typeof locLabels !== 'undefined' && locLabels) {
          info = locLabels[label] || locLabels[sanitizedLabel] || null;
        }
        return info;
      }
    }

    return null;
  };

  const handleLabelMessage = (payload) => {
    const labelInfo = getLabelInfo(payload);

    dispatch(setLabelDisplay(labelInfo));
  };

  const handleOverlayMessage = (payload) => {
    const parts = payload.split('|');
    const action = parts[0];
    switch (action) {
      case 'set': {
        if (parts.length < 6) {
          console.warn('TextOverlay.Set was incorrectly formatted: ', payload);
          break;
        }
        const id = parts[1];
        const xPos = parseFloat(parts[2]);
        const yPos = parseFloat(parts[3]);
        let text = encodeForHTML(parts[4]);
        const zIdx = parseInt(parts[5], 10);
        const labelStyle = parts[6] ?? 'name'; // Possible values are 'chat', 'name', and 'viewboard', default to 'name'
        const targetData = parts.length >= 8 ? parts[7] : '';

        let targetArray;
        let target;
        let peer;

        // if the text is "..." set it to the user's name instead.
        if (labelStyle === 'name') {
          // check that the targetData exists
          if (targetData !== '') {
            targetArray = targetData.split('.');
            target = targetArray[3];
            peer = peers[target];
          }
          // if the local user's location is the same as the peers location, process the update
          if (peer?.location !== location) {
            break;
          }
          if (text === '...') {
            // get the users nickname from the store
            const displayName =
              typeof peers[target] === 'undefined'
                ? target
                : peers[target].displayName;
            text = encodeForHTML(displayName);
          }
        }
        dispatch(updateTextOverlay(id, text, labelStyle, xPos, yPos, zIdx));
        break;
      }
      case 'remove': {
        const id = parts[1];
        dispatch(removeTextOverlay(id));
        break;
      }
      case 'clear': {
        // No params, just remove all labels
        dispatch(clearTextOverlays());
        break;
      }
      case 'end': {
        // No params, just end frame
        break;
      }
      default: {
        console.warn('Unexpected overlay action: ', payload);
        break;
      }
    }
  };

  const processAppearanceFromUnity = (encodedAppearance, setLocal = false) => {
    // 1 - decode the json
    const decoded = JSON.parse(base64.decode(encodedAppearance));
    // 2 - take a snapshot of the user's selection to be used for comparison / validation
    const localAppearanceSnapshot = JSON.parse(JSON.stringify(looks[body]));
    // 3 - create a newLooks object to be used in case any changes to redux are necessary. this is a copy of the existing looks object in redux (including all 3 body types).
    const newLooks = { ...looks };

    // 4 -convert decoded appearance from unity to same format as local state appearance
    // create a base object to start from, that mirrors the format we need. Put the head values in right off.
    let convertedUnityAppearance = JSON.parse(
      JSON.stringify(localAppearanceSnapshot),
    );
    convertedUnityAppearance.categories.head = {
      color: '',
      generateHead: false,
      selection: decoded.headAddress,
      texture: '',
    };

    // loop over all the categories in the Unity appearance to save them to the new format
    // This only includes bottom, shoes, top, (no head data)
    const unityCategories = decoded.clothingData;

    for (const category in unityCategories) {
      convertedUnityAppearance.categories[category].selection = unityCategories[
        category
      ].clothingAddress
        ? unityCategories[category].clothingAddress
        : '';
      convertedUnityAppearance.categories[category].texture = unityCategories[
        category
      ].textureAddress
        ? unityCategories[category].textureAddress
        : '';
      convertedUnityAppearance.categories[category].color = unityCategories[
        category
      ].color
        ? unityCategories[category].color
        : '';
    }

    // 5 - Now check the unity received data against the users recent selections in redux
    // the texture/generateHead piece of the head category in the local storage seems to missing often for some reason.  Add it back in to be sure it matches (head texture doesn't actually matter for this validation).
    localAppearanceSnapshot.categories.head.texture = '';
    localAppearanceSnapshot.categories.head.generateHead = false;

    if (!deepEqual(convertedUnityAppearance, localAppearanceSnapshot)) {
      // if the bodies do not match, then there was a problem. Let the user know.
      showMessage({
        message: translate('avatarPreview.avatarUpdateProblem'),
        icon: 'auto',
        autoHide: false,
        type: 'danger',
        floating: true,
        style: { width: 250 },
        titleStyle: { alignSelf: 'center' },
        hideOnPress: true,
        position: 'center',
      });
      // save the new appearance data to redux
      newLooks[body] = convertedUnityAppearance;
      dispatch(setAppearance(newLooks));
    }

    // 6 - save to local storage if indicated
    if (setLocal) {
      dispatch(setAppearanceInStorage(asdkId, body, newLooks));
    }

    // 7 - update the store loading back to false to show the UI again
    dispatch(setAvatarLoading(false));
  };

  const saveUnityAsdkId = (encodedAsdkId) => {
    // get the user ID from the message
    const userAsdkId = base64.decode(encodedAsdkId);
    // save the user ID to the redux store appearance
    dispatch(setAsdkId(userAsdkId));
  };

  return location && locationResourceID ? (
    <World
      location={locationResourceID}
      locationFilter={location}
      onLoadComplete={onLoadComplete}
      onTransformFromWorld={onTransformFromWorld}
      onLegacyFromWorld={onLegacyFromWorld}
      lowPower={lowPowerMode}
      showFloatingNames={floatingNames}
      style={worldStyle.world}
      dispatch={dispatch}
    />
  ) : null;
};

export default MeetingWorld;
