import {
    START_AI_TEACHER_LISTENING,
    STOP_AI_TEACHER_LISTENING,
    START_AI_ONETOONE_MEETING_LISTENING,
    STOP_AI_ONETOONE_MEETING_LISTENING
} from "./actionTypes";

export const startAITeacherListening = () => {
    return (dispatch) => {
        dispatch({
            type: START_AI_TEACHER_LISTENING,
        })
    };
}

export const stopAITeacherListening = () => {
    return (dispatch) => {
        dispatch({
            type: STOP_AI_TEACHER_LISTENING,
        })
    };
}

export const startOneOnOneMeeting = () => {
    return (dispatch) => {
        dispatch({
            type: START_AI_ONETOONE_MEETING_LISTENING,
        })
    };
}

export const stopOneOnOneMeeting = () => {
    return (dispatch) => {
        dispatch({
            type: STOP_AI_ONETOONE_MEETING_LISTENING,
        })
    };
}