import protooClient from 'protoo-client';
import axios from 'axios';
import * as mediasoupClient from 'mediasoup-client';
import {showMessage, hideMessage} from 'react-native-flash-message';

import Logger from './Logger';
import {getProtooUrl} from './urlFactory';
import * as requestActions from 'store/communication/actions/requestActions';
import * as stateActions from 'store/communication/actions/stateActions';
import {receiveMessage} from 'store/communication/actions/chatActions';
import {
  requestRecall,
  setLocation as setLocationAction,
} from 'store/world/actions';
import {disableMic, disableCamera, disableScreenShare} from 'store/media';
import World from 'components/World';
import {USER_ROUTE} from 'utils/World/config';
import {
  shouldUpdateTransformReduxState,
  shouldUpdateTransformUnity,
  convertMessageToTransform,
} from 'utils/World/Transform';
import {
  setInviteURL,
  setLocations,
  setIsMeetingValid,
} from 'store/meeting/actions/properties';
import {
  removeActiveViewboard,
  updateActiveViewboard,
  setViewboardContent,
} from 'store/viewboard';
import {logEvent} from 'utils/firebase/analytics';
import {truncateFirebaseAnalyticParameterValue} from 'utils/firebase/utils';
import {
  CLOSE_ROOM_FAILURE,
  SET_SANITIZED_USER_PERMISSIONS,
  TRANSLATION_FAILURE,
  TRANSLATION_SUCCESS,
} from 'utils/firebase/analytics.config';
import {
  getViewboardReducedMedia,
  getViewboardSanitizedContentId,
} from 'utils/helpers';
import {setIsBeingRecorded} from 'store/meeting/actions/recording';
import {ROOM_STATE} from './utils';
import {getHostName} from 'utils/url';

const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{googDscp: true}],
};

const VIDEO_SIMULCAST_ENCODINGS = [
  {scaleResolutionDownBy: 4},
  {scaleResolutionDownBy: 2},
  {scaleResolutionDownBy: 1},
];

// Used for VP9 webcam video.
const VIDEO_KSVC_ENCODINGS = [{scalabilityMode: 'S3T3_KEY'}];

// Used for VP9 desktop sharing.
const VIDEO_SVC_ENCODINGS = [{scalabilityMode: 'S3T3', dtx: true}];

// Used for live Translation
const TRANSLATOR_URL = 'https://translate.3dmeet.com/translate_v2/?lang=';
const API_ACCEPT_TYPES = 'application/json';
const TRANSLATION_API_KEY = '9c81a12b-3096-4c0e-837e-c1465f8b6e96';

const logger = new Logger('RoomClient');

let store;

export default class RoomClient {
  /**
   * @param  {Object} data
   * @param  {Object} data.store - The Redux store.
   */
  static init(data) {
    // Check existing store. If react is resetting, leave any meetings we are in.
    if (store) {
      if (store.getState().communication.control.roomClient) {
        store.getState().communication.control.roomClient.close();
      }
    }

    store = data.store;
  }

  constructor({
    token,
    callId,
    roomId,
    peerId,
    displayName,
    device,
    handlerName,
    useSimulcast,
    useSharingSimulcast,
    forceTcp,
    produce,
    consume,
    forceH264,
    forceVP9,
    svc,
    datachannel,
    communicationServer,
  }) {
    logger.debug(
      'constructor() [roomId:"%s", peerId:"%s", displayName:"%s", device:%s]',
      roomId,
      peerId,
      displayName,
      device.flag,
    );

    // Token to send to server
    // @type {String}
    this.token = token;

    // Call Id used for integrated calling on iOS
    // @type {String}
    this.callId = callId;

    // Meeting Id
    // @type {String}
    this.meetingId = roomId;

    // Closed flag.
    // @type {Boolean}
    this._closed = false;

    // Display name.
    // @type {String}
    this._displayName = displayName;

    // Device info.
    // @type {Object}
    this._device = device;

    // Whether we want to force RTC over TCP.
    // @type {Boolean}
    this._forceTcp = forceTcp;

    // Whether we want to produce audio/video.
    // @type {Boolean}
    this._produce = produce;

    // Whether we should consume.
    // @type {Boolean}
    this._consume = consume;

    // Whether we want DataChannels.
    // @type {Boolean}
    this._useDataChannel = datachannel;

    // Next expected dataChannel test number.
    // @type {Number}
    this._nextDataChannelTestNumber = 0;

    // Custom mediasoup-client handler name (to override default browser
    // detection if desired).
    // @type {String}
    this._handlerName = handlerName;

    // Whether simulcast should be used.
    // @type {Boolean}
    this._useSimulcast = useSimulcast;

    // Whether simulcast should be used in desktop sharing.
    // @type {Boolean}
    this._useSharingSimulcast = useSharingSimulcast;

    // Protoo URL.
    // @type {String}
    this._protooUrl = getProtooUrl({
      token: this.token,
      forceH264,
      forceVP9,
      communicationServer,
    });

    // protoo-client Peer instance.
    // @type {protooClient.Peer}
    this._protoo = null;

    // mediasoup-client Device instance.
    // @type {mediasoupClient.Device}
    this._mediasoupDevice = null;

    // mediasoup Transport for sending.
    // @type {mediasoupClient.Transport}
    this._sendTransport = null;

    // mediasoup Transport for receiving.
    // @type {mediasoupClient.Transport}
    this._recvTransport = null;

    // Local mic mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._micProducer = null;

    // Local webcam mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._webcamProducer = null;

    // Local share mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._shareProducer = null;

    // Local chat DataProducer.
    // @type {mediasoupClient.DataProducer}
    this._chatDataProducer = null;

    // mediasoup Consumers.
    // @type {Map<String, mediasoupClient.Consumer>}
    this._consumers = new Map();

    // mediasoup DataConsumers.
    // @type {Map<String, mediasoupClient.DataConsumer>}
    this._dataConsumers = new Map();

    // Map of webcam MediaDeviceInfos indexed by deviceId.
    // @type {Map<String, MediaDeviceInfos>}
    this._webcams = new Map();

    // Local Webcam.
    // @type {Object} with:
    // - {MediaDeviceInfo} [device]
    // - {String} [resolution] - 'qvga' / 'vga' / 'hd'.
    this._webcam = {
      device: null,
      resolution: 'hd',
    };

    // Object containing state for all the other people in the room
    this.peerState = {};

    // Object with local user's peer state
    this.localState = {};

    // Set custom SVC scalability mode.
    if (svc) {
      VIDEO_SVC_ENCODINGS[0].scalabilityMode = svc;
      VIDEO_KSVC_ENCODINGS[0].scalabilityMode = `${svc}_KEY`;
    }

    // The unsubscribe function from the redux store
    this.reduxSubscription = undefined;

    /**
     * Timeout handle for disconnect (used this comment format for VS Code's type helpers, single line comment for type doesn't get processed)
     * @type {NodeJS.Timeout}
     */
    this.disconnectTimeout = null;

    /**
     * Timeout handle for reconnect
     * @type {NodeJS.Timeout}
     */
    this.reconnectTimeout = null;
  }

  close() {
    if (this._closed) {
      return;
    }

    this._closed = true;

    if (this.reduxSubscription) {
      this.reduxSubscription();
      this.reduxSubscription = undefined;
    }

    logger.debug('close()');

    // Close protoo Peer
    this._protoo.close();

    // Cancel the timeout for the disconnect check
    clearTimeout(this.disconnectTimeout);
    clearTimeout(this.reconnectTimeout);

    // Close mediasoup Transports.
    if (this._sendTransport) {
      this._sendTransport.close();
    }

    if (this._recvTransport) {
      this._recvTransport.close();
    }

    if(store) store.dispatch(stateActions.setRoomState(ROOM_STATE.closed));
  }

  async join() {
    await store.dispatch(stateActions.clearConnectionFailure());

    const protooTransport = new protooClient.WebSocketTransport(
      this._protooUrl,
    );

    this._protoo = new protooClient.Peer(protooTransport);

    store.dispatch(stateActions.setRoomState(ROOM_STATE.connecting));

    this._protoo.on('open', () => {
      logger.debug('protoo connection opened');
    });

    this._protoo.on('failed', () => {
      store.dispatch(
        stateActions.setConnectionFailure(
          'failed',
          'WebSocket connection failed',
          'Could not connect to server.',
        ),
      );
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: 'WebSocket connection failed',
        }),
      );
    });

    this._protoo.on('disconnected', () => {
      store.dispatch(stateActions.setRoomState(ROOM_STATE.disconnected));
      store.dispatch(stateActions.clearConsumers());

      const DISCONNECTION_TIMEOUT = 30000;
      this.disconnectTimeout = setTimeout(() => {
        if (
          store.getState().communication.room.state === ROOM_STATE.disconnected
        ) {
          store.dispatch(
            stateActions.setConnectionFailure(
              'disconnected',
              'signal server disconnected',
              'Connection interrupted.',
            ),
          );
          store.dispatch(
            requestActions.notify({
              type: 'error',
              text: 'WebSocket disconnected',
            }),
          );

          this.close();
        }
      }, DISCONNECTION_TIMEOUT);
    });

    this._protoo.on('close', () => {
      if (this._closed) {
        return;
      }

      this.close();
    });

    this._protoo.on('request', async (request, accept, reject) => {
      logger.debug(
        'proto "request" event [method:%s, data:%o]',
        request.method,
        request.data,
      );

      switch (request.method) {
        case 'newConsumer': {
          if (!this._consume) {
            reject(403, 'I do not want to consume');

            break;
          }

          const {
            peerId,
            producerId,
            id,
            kind,
            rtpParameters,
            type,
            appData,
            producerPaused,
          } = request.data;

          let codecOptions;

          if (kind === 'audio') {
            codecOptions = {
              opusStereo: 1,
            };
          }

          try {
            const consumer = await this._recvTransport.consume({
              id,
              producerId,
              kind,
              rtpParameters,
              codecOptions,
              appData: {...appData, peerId}, // Trick.
            });

            // Store in the map.
            this._consumers.set(consumer.id, consumer);

            consumer.on('transportclose', () => {
              this._consumers.delete(consumer.id);
            });

            const {
              spatialLayers,
              temporalLayers,
            } = mediasoupClient.parseScalabilityMode(
              consumer.rtpParameters.encodings[0].scalabilityMode,
            );

            store.dispatch(
              stateActions.addConsumer(
                {
                  id: consumer.id,
                  type: type,
                  locallyPaused: false,
                  remotelyPaused: producerPaused,
                  rtpParameters: consumer.rtpParameters,
                  spatialLayers: spatialLayers,
                  temporalLayers: temporalLayers,
                  preferredSpatialLayer: spatialLayers - 1,
                  preferredTemporalLayer: temporalLayers - 1,
                  priority: 1,
                  codec: consumer.rtpParameters.codecs[0].mimeType.split(
                    '/',
                  )[1],
                  track: consumer.track,
                },
                peerId,
              ),
            );

            // We are ready. Answer the protoo request so the server will
            // resume this Consumer (which was paused for now if video).
            accept();

            const {disableAudio, disableVideo} = this._getConsumerPermissions(
              peerId,
            );
            if (consumer.kind === 'video' && disableVideo) {
              this._pauseConsumer(consumer);
            }
            if (consumer.kind === 'audio' && disableAudio) {
              this._pauseConsumer(consumer);
            }

            // If audio-only mode is enabled, pause it.
            if (
              consumer.kind === 'video' &&
              store.getState().communication.me.audioOnly
            ) {
              this._pauseConsumer(consumer);
            }
          } catch (error) {
            logger.error('"newConsumer" request failed:%o', error);

            store.dispatch(
              requestActions.notify({
                type: 'error',
                text: `Error creating a Consumer: ${error}`,
              }),
            );

            throw error;
          }

          break;
        }

        case 'newDataConsumer': {
          if (!this._consume) {
            reject(403, 'I do not want to data consume');

            break;
          }

          if (!this._useDataChannel) {
            reject(403, 'I do not want DataChannels');

            break;
          }

          const {
            peerId,
            dataProducerId,
            id,
            sctpStreamParameters,
            label,
            protocol,
            appData,
          } = request.data;

          try {
            const dataConsumer = await this._recvTransport.consumeData({
              id,
              dataProducerId,
              sctpStreamParameters,
              label,
              protocol,
              appData: {...appData, peerId}, // Trick.
            });

            // Store in the map.
            this._dataConsumers.set(dataConsumer.id, dataConsumer);

            dataConsumer.on('transportclose', () => {
              this._dataConsumers.delete(dataConsumer.id);
            });

            dataConsumer.on('open', () => {
              logger.debug('DataConsumer "open" event');
            });

            dataConsumer.on('close', () => {
              logger.warn('DataConsumer "close" event');

              this._dataConsumers.delete(dataConsumer.id);

              store.dispatch(
                requestActions.notify({
                  type: 'error',
                  text: 'DataConsumer closed',
                }),
              );
            });

            dataConsumer.on('error', (error) => {
              logger.error('DataConsumer "error" event:%o', error);

              store.dispatch(
                requestActions.notify({
                  type: 'error',
                  text: `DataConsumer error: ${error}`,
                }),
              );
            });

            dataConsumer.on('message', (message) => {
              logger.debug(
                'DataConsumer "message" event [streamId:%d]',
                dataConsumer.sctpStreamParameters.streamId,
              );

              // TODO: For debugging.
              window.DC_MESSAGE = message;

              if (message instanceof ArrayBuffer) {
                const view = new DataView(message);
                const number = view.getUint32();

                if (number === Math.pow(2, 32) - 1) {
                  logger.warn('dataChannelTest finished!');

                  this._nextDataChannelTestNumber = 0;

                  return;
                }

                if (number > this._nextDataChannelTestNumber) {
                  logger.warn(
                    'dataChannelTest: %s packets missing',
                    number - this._nextDataChannelTestNumber,
                  );
                }

                this._nextDataChannelTestNumber = number + 1;

                return;
              } else if (typeof message !== 'string') {
                logger.warn('ignoring DataConsumer "message" (not a string)');

                return;
              }

              const parsedMessage = JSON.parse(message);

              switch (dataConsumer.label) {
                case 'chat': {
                  const {peers} = store.getState().communication;
                  const peersArray = Object.keys(peers).map(
                    (_peerId) => peers[_peerId],
                  );
                  const sendingPeer = peersArray.find((peer) =>
                    peer.dataConsumers.includes(dataConsumer.id),
                  );

                  if (!sendingPeer) {
                    logger.warn('DataConsumer "message" from unknown peer');

                    break;
                  }

                  store.dispatch(
                    requestActions.notify({
                      title: `${sendingPeer.displayName} says:`,
                      text: parsedMessage.text,
                      timeout: 5000,
                    }),
                  );

                  // Overwrite user info to prevent spoofing
                  const verifiedMessage = Object.assign({}, parsedMessage, {
                    user: {
                      ...parsedMessage.user,
                      _id: sendingPeer.id,
                      name: sendingPeer.displayName,
                      avatar: sendingPeer.photoURL,
                    },
                  });

                  store.dispatch(receiveMessage(verifiedMessage));

                  break;
                }

                default: {
                  logger.warn(
                    'Unexpected message label [%s]',
                    dataConsumer.label,
                  );
                  break;
                }
              }
            });

            // TODO: REMOVE
            window.DC = dataConsumer;

            store.dispatch(
              stateActions.addDataConsumer(
                {
                  id: dataConsumer.id,
                  sctpStreamParameters: dataConsumer.sctpStreamParameters,
                  label: dataConsumer.label,
                  protocol: dataConsumer.protocol,
                },
                peerId,
              ),
            );

            // We are ready. Answer the protoo request.
            accept();
          } catch (error) {
            logger.error('"newDataConsumer" request failed:%o', error);

            store.dispatch(
              requestActions.notify({
                type: 'error',
                text: `Error creating a DataConsumer: ${error}`,
              }),
            );

            throw error;
          }

          break;
        }

        default: {
          logger.warn(
            'unexpected protoo request method [method:%s]',
            request.method,
          );
          break;
        }
      }
    });

    this._protoo.on('notification', (notification) => {
      logger.debug(
        'proto "notification" event [method:%s, data:%o]',
        notification.method,
        notification.data,
      );

      switch (notification.method) {
        case 'join': {
          this._joinRoom();
          this.sendState();
          break;
        }

        case 'selfInfo': {
          const sanitizedPermissions =
            notification && notification.data && notification.data.permissions;

          const sanitizedPermissionsString = sanitizedPermissions.join(' ');

          logEvent(SET_SANITIZED_USER_PERMISSIONS, {
            meeting_id: this.meetingId,
            permissions: truncateFirebaseAnalyticParameterValue(
              sanitizedPermissionsString,
            ),
          });

          store.dispatch(stateActions.setMeInfo(notification.data));
          break;
        }

        case 'kick': {
          const {reason} = notification.data;
          store.dispatch(stateActions.setConnectionFailure('kick', reason, ''));
          this.close();
          break;
        }

        case 'producerScore': {
          const {producerId, score} = notification.data;

          store.dispatch(stateActions.setProducerScore(producerId, score));

          break;
        }

        case 'newPeer': {
          const peer = notification.data;

          store.dispatch(
            stateActions.addPeer({...peer, consumers: [], dataConsumers: []}),
          );

          store.dispatch(
            requestActions.notify({
              text: `${peer.displayName} has joined the room`,
            }),
          );

          // When a new peer joins, set meeting to be valid for analytics if not already valid
          // A valid meeting is defined as a meeting that has at least 2 participants
          if (!store.getState().meeting.properties.setIsMeetingValid) {
            store.dispatch(setIsMeetingValid(true));
          }

          break;
        }

        case 'peerClosed': {
          const {peerId} = notification.data;

          store.dispatch(stateActions.removePeer(peerId));

          break;
        }

        case 'peerDisplayNameChanged': {
          const {peerId, displayName, oldDisplayName} = notification.data;

          store.dispatch(stateActions.setPeerDisplayName(displayName, peerId));

          store.dispatch(
            requestActions.notify({
              text: `${oldDisplayName} is now ${displayName}`,
            }),
          );

          this.updateFloatingName(peerId);

          break;
        }

        case 'peerInfoChanged': {
          const {peerId, displayName, photoURL} = notification.data;

          if (displayName && displayName !== '') {
            store.dispatch(
              stateActions.setPeerDisplayName(displayName, peerId),
              // HERE
            );
            this.updateFloatingName(peerId);
          }

          if (photoURL && photoURL !== '') {
            store.dispatch(stateActions.setPeerPhotoURL(photoURL, peerId));
          }

          break;
        }

        case 'peerAudioMuted': {
          const {peerId, muted} = notification.data;

          store.dispatch(stateActions.checkMeAudioMuted(peerId, muted));
          store.dispatch(stateActions.setPeerAudioMuted(peerId, muted));

          break;
        }

        case 'peerVideoMuted': {
          const {peerId, muted} = notification.data;

          store.dispatch(stateActions.checkMeVideoMuted(peerId, muted));
          store.dispatch(stateActions.setPeerVideoMuted(peerId, muted));

          break;
        }

        case 'peerChatMuted': {
          const {peerId, muted} = notification.data;

          store.dispatch(stateActions.checkMeChatMuted(peerId, muted));
          store.dispatch(stateActions.setPeerChatMuted(peerId, muted));

          break;
        }

        case 'consumerClosed': {
          const {consumerId} = notification.data;
          const consumer = this._consumers.get(consumerId);

          if (!consumer) {
            break;
          }

          consumer.close();

          this._consumers.delete(consumerId);

          const {peerId} = consumer.appData;

          store.dispatch(stateActions.removeConsumer(consumerId, peerId));

          break;
        }

        case 'consumerPaused': {
          const {consumerId} = notification.data;
          const consumer = this._consumers.get(consumerId);

          if (!consumer) {
            break;
          }

          store.dispatch(stateActions.setConsumerPaused(consumerId, 'remote'));

          break;
        }

        case 'consumerResumed': {
          const {consumerId} = notification.data;
          const consumer = this._consumers.get(consumerId);

          if (!consumer) {
            break;
          }

          store.dispatch(stateActions.setConsumerResumed(consumerId, 'remote'));

          break;
        }

        case 'producerPaused': {
          const {producerId} = notification.data;
          const producer = this.getProducer(producerId);

          if (!producer) {
            break;
          }

          store.dispatch(stateActions.setProducerPaused(producerId));
          this.handleRemotePauseProducer(producerId);
          break;
        }

        case 'producerResumed': {
          const {producerId} = notification.data;
          const producer = this.getProducer(producerId);

          if (!producer) {
            break;
          }

          store.dispatch(stateActions.setProducerResumed(producerId));

          break;
        }

        case 'consumerLayersChanged': {
          const {consumerId, spatialLayer, temporalLayer} = notification.data;
          const consumer = this._consumers.get(consumerId);

          if (!consumer) {
            break;
          }

          store.dispatch(
            stateActions.setConsumerCurrentLayers(
              consumerId,
              spatialLayer,
              temporalLayer,
            ),
          );

          break;
        }

        case 'consumerScore': {
          const {consumerId, score} = notification.data;

          store.dispatch(stateActions.setConsumerScore(consumerId, score));

          break;
        }

        case 'dataConsumerClosed': {
          const {dataConsumerId} = notification.data;
          const dataConsumer = this._dataConsumers.get(dataConsumerId);

          if (!dataConsumer) {
            break;
          }

          dataConsumer.close();
          this._dataConsumers.delete(dataConsumerId);

          const {peerId} = dataConsumer.appData;

          store.dispatch(
            stateActions.removeDataConsumer(dataConsumerId, peerId),
          );

          break;
        }

        case 'activeSpeaker': {
          const {peerId, volume} = notification.data;

          let convertedVolume = volume === undefined ? 0 : volume;
          if (convertedVolume !== 0) {
            // Volume goes from -127 to 0 in dBvo (?), converting that to a percentage and inverting it seems to work well enough
            convertedVolume = 1 - convertedVolume / -127;
          }
          store.dispatch(
            stateActions.setRoomActiveSpeaker(peerId, convertedVolume),
          );

          break;
        }

        case 'peerJoined': {
          break;
        }

        case 'world': {
          const {payload, route, peer} = notification.data;
          if (route === USER_ROUTE) {
            if (payload.startsWith('transform|')) {
              const currentPeerTransformData = store.getState().communication
                .peers[peer]?.transform;
              const newTransformData = convertMessageToTransform(payload);

              if (
                shouldUpdateTransformReduxState({
                  oldTransformData: currentPeerTransformData,
                  newTransformData,
                })
              ) {
                store.dispatch(stateActions.setPeerTransform(peer, payload));
              }

              if (
                shouldUpdateTransformUnity({
                  oldTransformData: currentPeerTransformData,
                  newTransformData,
                })
              ) {
                World.onReceivePeerMessage(notification.data);
              }
            }

            if (payload.startsWith('anim|')) {
              store.dispatch(stateActions.setPeerEmotes(peer, payload));
              World.onReceivePeerMessage(notification.data);
            }
            World.onReceivePeerMessage(notification.data);
          } else {
            World.onReceivePeerMessage(notification.data);
          }

          break;
        }

        case 'peerState': {
          const {peerId, state} = notification.data;
          // Don't allow nickname to be set here, we'll merge displayName into it where needed since that has server-side controls
          delete state.nickname;
          this.peerState[peerId] = state;
          const {location} = state;

          store.dispatch(stateActions.setPeerLocation(peerId, location));

          // if the peer's location is not the same as the local user's location - remove the user from the unity world. They will be rebuilt when they are in the same world again.
          if (location !== this.localState.location) {
            World.runCommand(`player_leave ${peerId}`);
          }

          this.syncPeerState(peerId, state);

          break;
        }

        case 'peerLeft': {
          const {peerId} = notification.data;

          delete this.peerState[peerId];

          World.runCommand(`player_leave ${peerId}`);
          break;
        }

        case 'recall': {
          const {peerId, location} = notification.data;

          store.dispatch(requestRecall(peerId, location));
          break;
        }

        case 'roomAudioMuted': {
          const {muted} = notification.data;

          store.dispatch(stateActions.setRoomAudioMutedState(muted));

          break;
        }

        case 'roomVideoMuted': {
          const {muted} = notification.data;

          store.dispatch(stateActions.setRoomVideoMutedState(muted));

          break;
        }

        case 'roomChatMuted': {
          const {muted} = notification.data;

          store.dispatch(stateActions.setRoomChatMutedState(muted));

          break;
        }

        case 'roomVoicePositionalModeChanged': {
          const {mode} = notification.data;

          store.dispatch(stateActions.setRoomVoicePositionalModeState(mode));

          break;
        }

        case 'roomVideoPositionalModeChanged': {
          const {mode} = notification.data;

          store.dispatch(stateActions.setRoomVideoPositionalModeState(mode));

          break;
        }

        case 'roomConfigs': {
          this.setLocalRoomConfigs(notification.data);

          break;
        }

        case 'viewboardContentSet': {
          notification.data.forEach((contentData) => {
            const {viewboard, content} = contentData;
            const contentItem = store
              .getState()
              .viewboard.content.find((c) => c.id === content.id);

            const reducedMedia = getViewboardReducedMedia(contentItem);
            const sanitizedContentId = getViewboardSanitizedContentId(
              contentItem,
            );

            World.runCommand(
              `play_channel_direct ${viewboard} ${sanitizedContentId} ${reducedMedia}`,
            );
            World.runCommand(`viewboard_broadcast ${viewboard} false`);
            World.runCommand(`viewboard_reciever ${viewboard} true`);

            store.dispatch(
              updateActiveViewboard({
                viewboardId: viewboard,
                contentId: content?.id,
              }),
            );
          });
          break;
        }

        case 'viewboardContentCleared': {
          notification.data.forEach((vbData) => {
            const {viewboard} = vbData;
            World.runCommand(`viewboard_reciever ${viewboard} false`);
            World.runCommand(`viewboard_clear ${viewboard}`);

            store.dispatch(
              removeActiveViewboard({
                viewboardId: viewboard,
              }),
            );
          });
          break;
        }

        case 'timeRemaining': {
          const {seconds} = notification.data;

          console.log(`Time remaining: ${seconds}`);

          break;
        }

        case 'recordingMeeting': {
          const recording = notification.data;

          store.dispatch(setIsBeingRecorded(recording));

          break;
        }

        default: {
          logger.error(
            'unknown protoo notification.method "%s"',
            notification.method,
          );
        }
      }
    });
  }

  async setViewboardContent(location, viewboard, id, element) {
    logger.debug(
      'setViewboardContent() [location:"%s", viewboard:"%s", content:"%s", media:"%s"]',
      location,
      viewboard,
      id,
      element,
    );
    try {
      const viewboardContent = await this._protoo.request(
        'setViewboardContent',
        {location, viewboard, content: {id, elementIndex: element}},
      );

      World.runCommand(`viewboard_broadcast ${viewboard} true`);
      return viewboardContent;
    } catch (error) {
      logger.error('setViewboardContent() | failed: %o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Could not set viewboard content: ${error}`,
        }),
      );
      return null;
    }
  }

  async clearViewboardContent(location, viewboard) {
    logger.debug(
      'clearViewboardContent() [location:"%s", viewboard:"%s"]',
      location,
      viewboard,
    );
    try {
      const viewboardContent = await this._protoo.request(
        'clearViewboardContent',
        {location, viewboard},
      );
      World.runCommand(`viewboard_broadcast ${viewboard} false`);
      return viewboardContent;
    } catch (error) {
      logger.error('clearViewboardContent() | failed: %o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Could not clear viewboard content: ${error}`,
        }),
      );
      return null;
    }
  }

  async closeProducer(producer) {
    producer.close();
    try {
      await this._protoo.request('closeProducer', {
        producerId: producer.id,
      });
    } catch (error) {
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error closing server-side webcam Producer: ${error}`,
        }),
      );
    }

    if (this._micProducer && producer.id === this._micProducer.id) {
      this._micProducer = null;
    } else if (producer.id === this._webcamProducer) {
      this._webcamProducer = null;
    } else if (producer.id === this._shareProducer) {
      this._shareProducer = null;
    }
    store.dispatch(stateActions.removeProducer(producer.id));
  }

  async setMicStream(stream) {
    this.currentMicStream = stream;
    this.changingMic = true;

    if (stream && !this._mediasoupDevice.canProduce('audio')) {
      logger.error('setMicStream() | cannot produce audio');

      this.changingMic = false;
      return;
    }

    try {
      // Check if we are already using this device to produce audio
      if (stream) {
        const track = stream.getAudioTracks()[0];

        if (this._micProducer && this._micProducer.track === track) {
          this.changingMic = false;
          return;
        }
      }

      if (!stream && !this._micProducer) {
        this.changingMic = false;
        return;
      }

      // Shut down the old producer
      if (this._micProducer) {
        await this.closeProducer(this._micProducer);
      }

      if (stream) {
        const track = stream.getAudioTracks()[0];

        this._micProducer = await this._sendTransport.produce({
          track,
          codecOptions: {
            opusStereo: 1,
            opusDtx: 1,
          },
        });

        store.dispatch(
          stateActions.addProducer({
            id: this._micProducer.id,
            paused: this._micProducer.paused,
            track: this._micProducer.track,
            rtpParameters: this._micProducer.rtpParameters,
            codec: this._micProducer.rtpParameters.codecs[0].mimeType.split(
              '/',
            )[1],
          }),
        );

        this._micProducer.on('transportclose', () => {
          this._micProducer = null;
        });

        this._micProducer.on('trackended', () => {
          store.dispatch(
            requestActions.notify({
              type: 'error',
              text: 'Microphone disconnected!',
            }),
          );
          const producer = this._micProducer;
          this._micProducer = undefined;
          this.closeProducer(producer).catch(() => {});
        });
      }
    } catch (error) {
      logger.error('setMicStream() | failed:%o', error);

      store.dispatch(disableMic());

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error enabling microphone: ${error}`,
        }),
      );
    }
    this.changingMic = false;
    // Make sure we are still synced, in case things changed
    this.onReduxStoreChange();
  }

  async setWebcamStream(stream) {
    this.currentWebcamStream = stream;
    this.changingWebcam = true;
    logger.debug('setWebcamStream()');

    if (this._shareProducer) {
      await this.disableShare();
    }

    if (stream && !this._mediasoupDevice.canProduce('video')) {
      logger.error('setWebcamStream() | cannot produce video');

      this.changingWebcam = false;
      return;
    }

    try {
      // Check if we are already using this device to produce video
      if (stream) {
        const track = stream.getVideoTracks()[0];

        if (this._webcamProducer && this._webcamProducer.track === track) {
          this.changingWebcam = false;
          return;
        }
      }

      if (!stream && !this._webcamProducer) {
        this.changingWebcam = false;
        return;
      }

      // Shut down the old producer
      if (this._webcamProducer) {
        await this.closeProducer(this._webcamProducer);
      }

      if (stream) {
        const track = stream.getVideoTracks()[0];

        if (this._useSimulcast) {
          // If VP9 is the only available video codec then use SVC.
          const firstVideoCodec = this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.kind === 'video',
          );

          let encodings;

          if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
            encodings = VIDEO_KSVC_ENCODINGS;
          } else {
            encodings = VIDEO_SIMULCAST_ENCODINGS;
          }

          this._webcamProducer = await this._sendTransport.produce({
            track,
            encodings,
            codecOptions: {
              videoGoogleStartBitrate: 1000,
            },
          });
        } else {
          this._webcamProducer = await this._sendTransport.produce({track});
        }

        store.dispatch(
          stateActions.addProducer({
            id: this._webcamProducer.id,
            deviceLabel: 'webcam',
            type: 'webcam',
            paused: this._webcamProducer.paused,
            track: this._webcamProducer.track,
            rtpParameters: this._webcamProducer.rtpParameters,
            codec: this._webcamProducer.rtpParameters.codecs[0].mimeType.split(
              '/',
            )[1],
          }),
        );

        this._webcamProducer.on('transportclose', () => {
          this._webcamProducer = null;
        });

        this._webcamProducer.on('trackended', () => {
          store.dispatch(
            requestActions.notify({
              type: 'error',
              text: 'Webcam disconnected!',
            }),
          );

          const producer = this._webcamProducer;
          this._webcamProducer = undefined;
          this.closeProducer(producer).catch(() => {});
        });
      }
    } catch (error) {
      logger.error('setWebcamStream() | failed:%o', error);

      store.dispatch(disableCamera());

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error enabling webcam: ${error}`,
        }),
      );
    }

    store.dispatch(stateActions.setWebcamInProgress(false));
    this.changingWebcam = false;
    // Make sure we are still synced, in case things changed
    this.onReduxStoreChange();
  }

  async disableWebcam() {
    logger.debug('disableWebcam()');

    if (!this._webcamProducer) {
      return;
    }

    this._webcamProducer.close();

    store.dispatch(stateActions.removeProducer(this._webcamProducer.id));

    try {
      await this._protoo.request('closeProducer', {
        producerId: this._webcamProducer.id,
      });
    } catch (error) {
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error closing server-side webcam Producer: ${error}`,
        }),
      );
    }

    this._webcamProducer = null;

    // Flips UI state to match camera state
    store.dispatch(disableCamera());
  }

  async muteMic() {
    this.changingMic = true;
    logger.debug('muteMic()');

    this._micProducer.pause();

    try {
      await this._protoo.request('pauseProducer', {
        producerId: this._micProducer.id,
      });

      store.dispatch(stateActions.setProducerPaused(this._micProducer.id));
    } catch (error) {
      logger.error('muteMic() | failed: %o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error pausing server-side mic Producer: ${error}`,
        }),
      );
    }
    this.changingMic = false;
    // Make sure we are still synced, in case things changed
    this.onReduxStoreChange();
  }

  async unmuteMic() {
    this.changingMic = true;
    logger.debug('unmuteMic()');

    this._micProducer.resume();

    try {
      await this._protoo.request('resumeProducer', {
        producerId: this._micProducer.id,
      });

      store.dispatch(stateActions.setProducerResumed(this._micProducer.id));
    } catch (error) {
      logger.error('unmuteMic() | failed: %o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error resuming server-side mic Producer: ${error}`,
        }),
      );
    }
    this.changingMic = false;
    // Make sure we are still synced, in case things changed
    this.onReduxStoreChange();
  }

  async enableShare() {
    logger.debug('enableShare()');

    if (this._shareProducer) {
      return;
    } else if (this._webcamProducer) {
      await this.disableWebcam();
    }

    if (!this._mediasoupDevice.canProduce('video')) {
      logger.error('enableShare() | cannot produce video');

      return;
    }

    let track;

    store.dispatch(stateActions.setShareInProgress(true));

    try {
      logger.debug('enableShare() | calling getUserMedia()');

      const stream = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          displaySurface: 'monitor',
          logicalSurface: true,
          cursor: true,
          width: {max: 1920},
          height: {max: 1080},
          frame: {max: 30},
        },
      });

      // May mean cancelled (in some implementations).
      if (!stream) {
        store.dispatch(stateActions.setShareInProgress(true));

        return;
      }

      track = stream.getVideoTracks()[0];

      if (this._useSharingSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.kind === 'video',
        );

        let encodings;

        if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
          encodings = VIDEO_SVC_ENCODINGS;
        } else {
          encodings = VIDEO_SIMULCAST_ENCODINGS.map((encoding) => ({
            ...encoding,
            dtx: true,
          }));
        }

        this._shareProducer = await this._sendTransport.produce({
          track,
          encodings,
          codecOptions: {
            videoGoogleStartBitrate: 1000,
          },
          appData: {
            share: true,
          },
        });
      } else {
        this._shareProducer = await this._sendTransport.produce({track});
      }

      store.dispatch(
        stateActions.addProducer({
          id: this._shareProducer.id,
          type: 'share',
          paused: this._shareProducer.paused,
          track: this._shareProducer.track,
          rtpParameters: this._shareProducer.rtpParameters,
          codec: this._shareProducer.rtpParameters.codecs[0].mimeType.split(
            '/',
          )[1],
        }),
      );

      this._shareProducer.on('transportclose', () => {
        this._shareProducer = null;
      });

      this._shareProducer.on('trackended', () => {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: 'Share disconnected!',
          }),
        );

        this.disableShare().catch(() => {});
      });
    } catch (error) {
      logger.error('enableShare() | failed:%o', error);

      store.dispatch(disableScreenShare());

      if (error.name !== 'NotAllowedError') {
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: `Error sharing: ${error}`,
          }),
        );

        // eslint-disable-next-line no-alert
        window.alert(
          'Please use a Chromium based browser (Chrome, Opera, [new] Edge), Firefox, or latest version of Safari to share your screen.',
        );
      }

      store.dispatch(disableScreenShare());

      if (track) {
        track.stop();
      }
    }

    store.dispatch(stateActions.setShareInProgress(false));
  }

  async disableShare() {
    logger.debug('disableShare()');

    if (!this._shareProducer) {
      return;
    }

    this._shareProducer.close();

    store.dispatch(stateActions.removeProducer(this._shareProducer.id));

    try {
      await this._protoo.request('closeProducer', {
        producerId: this._shareProducer.id,
      });
    } catch (error) {
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error closing server-side share Producer: ${error}`,
        }),
      );
    }

    this._shareProducer = null;

    // Flips UI state to match screenshare state
    store.dispatch(disableScreenShare());
  }

  async requestRecording() {
    return await this._protoo.request('startRecordingMeeting');
  }

  notifyEndRecording() {
    this._protoo.request('stopRecordingMeeting');
  }

  async restartIce() {
    logger.debug('restartIce()');

    store.dispatch(stateActions.setRestartIceInProgress(true));

    try {
      if (this._sendTransport) {
        const iceParameters = await this._protoo.request('restartIce', {
          transportId: this._sendTransport.id,
        });

        await this._sendTransport.restartIce({iceParameters});
      }

      if (this._recvTransport) {
        const iceParameters = await this._protoo.request('restartIce', {
          transportId: this._recvTransport.id,
        });

        await this._recvTransport.restartIce({iceParameters});
      }

      store.dispatch(
        requestActions.notify({
          text: 'ICE restarted',
        }),
      );
    } catch (error) {
      logger.error('restartIce() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `ICE restart failed: ${error}`,
        }),
      );
    }

    store.dispatch(stateActions.setRestartIceInProgress(false));
  }

  async setMaxSendingSpatialLayer(spatialLayer) {
    logger.debug('setMaxSendingSpatialLayer() [spatialLayer:%s]', spatialLayer);

    try {
      if (this._webcamProducer) {
        await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
      } else if (this._shareProducer) {
        await this._shareProducer.setMaxSpatialLayer(spatialLayer);
      }
    } catch (error) {
      logger.error('setMaxSendingSpatialLayer() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error setting max sending video spatial layer: ${error}`,
        }),
      );
    }
  }

  async setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer) {
    logger.debug(
      'setConsumerPreferredLayers() [consumerId:%s, spatialLayer:%s, temporalLayer:%s]',
      consumerId,
      spatialLayer,
      temporalLayer,
    );

    try {
      await this._protoo.request('setConsumerPreferredLayers', {
        consumerId,
        spatialLayer,
        temporalLayer,
      });

      store.dispatch(
        stateActions.setConsumerPreferredLayers(
          consumerId,
          spatialLayer,
          temporalLayer,
        ),
      );
    } catch (error) {
      logger.error('setConsumerPreferredLayers() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error setting Consumer preferred layers: ${error}`,
        }),
      );
    }
  }

  async setConsumerPriority(consumerId, priority) {
    logger.debug(
      'setConsumerPriority() [consumerId:%s, priority:%d]',
      consumerId,
      priority,
    );

    try {
      await this._protoo.request('setConsumerPriority', {
        consumerId,
        priority,
      });

      store.dispatch(stateActions.setConsumerPriority(consumerId, priority));
    } catch (error) {
      logger.error('setConsumerPriority() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error setting Consumer priority: ${error}`,
        }),
      );
    }
  }

  async requestConsumerKeyFrame(consumerId) {
    logger.debug('requestConsumerKeyFrame() [consumerId:%s]', consumerId);

    try {
      await this._protoo.request('requestConsumerKeyFrame', {consumerId});

      store.dispatch(
        requestActions.notify({
          text: 'Keyframe requested for video consumer',
        }),
      );
    } catch (error) {
      logger.error('requestConsumerKeyFrame() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error requesting key frame for Consumer: ${error}`,
        }),
      );
    }
  }

  async enableChatDataProducer() {
    logger.debug('enableChatDataProducer()');

    if (!this._useDataChannel) {
      return;
    }

    // NOTE: Should enable this code but it's useful for testing.
    if (this._chatDataProducer) {
      return;
    }

    try {
      // Create chat DataProducer.
      this._chatDataProducer = await this._sendTransport.produceData({
        ordered: false,
        maxRetransmits: 1,
        label: 'chat',
        priority: 'medium',
        appData: {info: 'my-chat-DataProducer'},
      });

      store.dispatch(
        stateActions.addDataProducer({
          id: this._chatDataProducer.id,
          sctpStreamParameters: this._chatDataProducer.sctpStreamParameters,
          label: this._chatDataProducer.label,
          protocol: this._chatDataProducer.protocol,
        }),
      );

      this._chatDataProducer.on('transportclose', () => {
        this._chatDataProducer = null;
      });

      this._chatDataProducer.on('open', () => {
        logger.debug('chat DataProducer "open" event');
      });

      this._chatDataProducer.on('close', () => {
        logger.error('chat DataProducer "close" event');

        this._chatDataProducer = null;

        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: 'Chat DataProducer closed',
          }),
        );
      });

      this._chatDataProducer.on('error', (error) => {
        logger.error('chat DataProducer "error" event:%o', error);

        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: `Chat DataProducer error: ${error}`,
          }),
        );
      });

      this._chatDataProducer.on('bufferedamountlow', () => {
        logger.debug('chat DataProducer "bufferedamountlow" event');
      });
    } catch (error) {
      logger.error('enableChatDataProducer() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error enabling chat DataProducer: ${error}`,
        }),
      );

      throw error;
    }
  }

  async sendChatMessage(message) {
    logger.debug('sendChatMessage() [text:"%s"]', message.text);

    if (!this._chatDataProducer) {
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: 'No chat DataProducer',
        }),
      );

      return;
    }

    try {
      const stringifiedMessage = JSON.stringify(message);
      this._chatDataProducer.send(stringifiedMessage);
    } catch (error) {
      logger.error('chat DataProducer.send() failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `chat DataProducer.send() failed: ${error}`,
        }),
      );
    }
  }

  async fetchMessageTranslation(liveTranslation, targetLanguage, message) {
    let text;
    try {
      if (liveTranslation) {
        const config = {
          headers: {
            accept: API_ACCEPT_TYPES,
            'api-key': TRANSLATION_API_KEY,
          },
        };

        const url =
          TRANSLATOR_URL +
          targetLanguage +
          '&text=' +
          encodeURIComponent(message.text);
        const result = await axios.get(url, config);
        const data = JSON.parse(result?.data);
        text = data?.result;
        logEvent(TRANSLATION_SUCCESS);
      }
    } catch (error) {
      logEvent(TRANSLATION_FAILURE, {
        error: error,
      });
      console.error('Translation error: ', error);
    } finally {
      return {
        ...message,
        translatedText: text,
      };
    }
  }

  async changeDisplayName(displayName) {
    logger.debug('changeDisplayName() [displayName:"%s"]', displayName);

    try {
      await this._protoo.request('changeDisplayName', {displayName});

      this._displayName = displayName;

      store.dispatch(stateActions.setDisplayName(displayName));

      store.dispatch(
        requestActions.notify({
          text: 'Display name changed',
        }),
      );
    } catch (error) {
      logger.error('changeDisplayName() | failed: %o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Could not change display name: ${error}`,
        }),
      );

      // We need to refresh the component for it to render the previous
      // displayName again.
      store.dispatch(stateActions.setDisplayName());
    }
  }

  async getSendTransportRemoteStats() {
    logger.debug('getSendTransportRemoteStats()');

    if (!this._sendTransport) {
      return;
    }

    return this._protoo.request('getTransportStats', {
      transportId: this._sendTransport.id,
    });
  }

  async getRecvTransportRemoteStats() {
    logger.debug('getRecvTransportRemoteStats()');

    if (!this._recvTransport) {
      return;
    }

    return this._protoo.request('getTransportStats', {
      transportId: this._recvTransport.id,
    });
  }

  async getAudioRemoteStats() {
    logger.debug('getAudioRemoteStats()');

    if (!this._micProducer) {
      return;
    }

    return this._protoo.request('getProducerStats', {
      producerId: this._micProducer.id,
    });
  }

  async getVideoRemoteStats() {
    logger.debug('getVideoRemoteStats()');

    const producer = this._webcamProducer || this._shareProducer;

    if (!producer) {
      return;
    }

    return this._protoo.request('getProducerStats', {
      producerId: producer.id,
    });
  }

  async getConsumerRemoteStats(consumerId) {
    logger.debug('getConsumerRemoteStats()');

    const consumer = this._consumers.get(consumerId);

    if (!consumer) {
      return;
    }

    return this._protoo.request('getConsumerStats', {consumerId});
  }

  async getChatDataProducerRemoteStats() {
    logger.debug('getChatDataProducerRemoteStats()');

    const dataProducer = this._chatDataProducer;

    if (!dataProducer) {
      return;
    }

    return this._protoo.request('getDataProducerStats', {
      dataProducerId: dataProducer.id,
    });
  }

  async getDataConsumerRemoteStats(dataConsumerId) {
    logger.debug('getDataConsumerRemoteStats()');

    const dataConsumer = this._dataConsumers.get(dataConsumerId);

    if (!dataConsumer) {
      return;
    }

    return this._protoo.request('getDataConsumerStats', {dataConsumerId});
  }

  async getSendTransportLocalStats() {
    logger.debug('getSendTransportLocalStats()');

    if (!this._sendTransport) {
      return;
    }

    return this._sendTransport.getStats();
  }

  async getRecvTransportLocalStats() {
    logger.debug('getRecvTransportLocalStats()');

    if (!this._recvTransport) {
      return;
    }

    return this._recvTransport.getStats();
  }

  async getAudioLocalStats() {
    logger.debug('getAudioLocalStats()');

    if (!this._micProducer) {
      return;
    }

    return this._micProducer.getStats();
  }

  async getVideoLocalStats() {
    logger.debug('getVideoLocalStats()');

    const producer = this._webcamProducer || this._shareProducer;

    if (!producer) {
      return;
    }

    return producer.getStats();
  }

  async getConsumerLocalStats(consumerId) {
    const consumer = this._consumers.get(consumerId);

    if (!consumer) {
      return;
    }

    return consumer.getStats();
  }

  async applyNetworkThrottle({uplink, downlink, rtt, secret}) {
    logger.debug(
      'applyNetworkThrottle() [uplink:%s, downlink:%s, rtt:%s]',
      uplink,
      downlink,
      rtt,
    );

    try {
      await this._protoo.request('applyNetworkThrottle', {
        uplink,
        downlink,
        rtt,
        secret,
      });
    } catch (error) {
      logger.error('applyNetworkThrottle() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error applying network throttle: ${error}`,
        }),
      );
    }
  }

  async resetNetworkThrottle({silent = false, secret}) {
    logger.debug('resetNetworkThrottle()');

    try {
      await this._protoo.request('resetNetworkThrottle', {secret});
    } catch (error) {
      if (!silent) {
        logger.error('resetNetworkThrottle() | failed:%o', error);

        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: `Error resetting network throttle: ${error}`,
          }),
        );
      }
    }
  }

  _getConsumerPermissions(peerId) {
    const attendanceInfo = store.getState().meeting.attendance.peers[peerId];
    const {disableAudio, disableVideo} = attendanceInfo ? attendanceInfo : {};
    const incomingVideoEnabled = store.getState().meeting.settings
      .incomingVideoEnabled;
    return {
      disableAudio,
      disableVideo: disableVideo || !incomingVideoEnabled,
    };
  }

  async syncAllConsumers() {
    const {peers} = store.getState().communication;
    const peerIds = Object.keys(peers);
    peerIds.forEach((peerId) => {
      this.syncConsumers(peerId);
    });
  }

  async syncConsumers(peerId) {
    const {disableAudio, disableVideo} = this._getConsumerPermissions(peerId);
    const {peers} = store.getState().communication;
    const peer = peers[peerId];
    const consumerIds = peer.consumers;

    consumerIds.forEach((consumerId) => {
      const consumer = this._consumers.get(consumerId);
      if (consumer.kind === 'audio') {
        if (disableAudio && !consumer.paused) {
          this._pauseConsumer(consumer);
        } else if (consumer.paused && !disableAudio) {
          this._resumeConsumer(consumer);
        }
      } else if (consumer.kind === 'video') {
        if (disableVideo && !consumer.paused) {
          this._pauseConsumer(consumer);
        } else if (consumer.paused && !disableVideo) {
          this._resumeConsumer(consumer);
        }
      }
    });
  }

  async _joinRoom() {
    logger.debug('_joinRoom()');

    try {
      this._mediasoupDevice = new mediasoupClient.Device({
        handlerName: this._handlerName,
      });

      const routerRtpCapabilities = await this._protoo.request(
        'getRouterRtpCapabilities',
      );

      await this._mediasoupDevice.load({routerRtpCapabilities});

      // Create mediasoup Transport for sending (unless we don't want to produce).
      if (this._produce) {
        const transportInfo = await this._protoo.request(
          'createWebRtcTransport',
          {
            forceTcp: this._forceTcp,
            producing: true,
            consuming: false,
            sctpCapabilities: this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined,
          },
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this._sendTransport = this._mediasoupDevice.createSendTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: [],
          proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
        });

        this._sendTransport.on(
          'connect', // eslint-disable-next-line no-shadow
          ({dtlsParameters}, callback, errback) => {
            this._protoo
              .request('connectWebRtcTransport', {
                transportId: this._sendTransport.id,
                dtlsParameters,
              })
              .then(callback)
              .catch(errback);
          },
        );

        this._sendTransport.on(
          'produce',
          async ({kind, rtpParameters, appData}, callback, errback) => {
            try {
              // eslint-disable-next-line no-shadow
              const {id} = await this._protoo.request('produce', {
                transportId: this._sendTransport.id,
                kind,
                rtpParameters,
                appData,
              });

              callback({id});
            } catch (error) {
              errback(error);
            }
          },
        );

        this._sendTransport.on(
          'producedata',
          async (
            {sctpStreamParameters, label, protocol, appData},
            callback,
            errback,
          ) => {
            logger.debug(
              '"producedata" event: [sctpStreamParameters:%o, appData:%o]',
              sctpStreamParameters,
              appData,
            );

            try {
              // eslint-disable-next-line no-shadow
              const {id} = await this._protoo.request('produceData', {
                transportId: this._sendTransport.id,
                sctpStreamParameters,
                label,
                protocol,
                appData,
              });

              callback({id});
            } catch (error) {
              errback(error);
            }
          },
        );
      }

      // Create mediasoup Transport for sending (unless we don't want to consume).
      if (this._consume) {
        const transportInfo = await this._protoo.request(
          'createWebRtcTransport',
          {
            forceTcp: this._forceTcp,
            producing: false,
            consuming: true,
            sctpCapabilities: this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined,
          },
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this._recvTransport = this._mediasoupDevice.createRecvTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: [],
        });

        this._recvTransport.on(
          'connect', // eslint-disable-next-line no-shadow
          ({dtlsParameters}, callback, errback) => {
            this._protoo
              .request('connectWebRtcTransport', {
                transportId: this._recvTransport.id,
                dtlsParameters,
              })
              .then(callback)
              .catch(errback);
          },
        );
      }

      // Join now into the room.
      // NOTE: Don't send our RTP capabilities if we don't want to consume.
      const {peers} = await this._protoo.request('joinMediaChannel', {
        displayName: this._displayName,
        device: this._device,
        rtpCapabilities: this._consume
          ? this._mediasoupDevice.rtpCapabilities
          : undefined,
        sctpCapabilities:
          this._useDataChannel && this._consume
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
      });

      if (
        store.getState().communication.room.state === ROOM_STATE.disconnected
      ) {
        // Disable user mic / camera on disconnect
        store.dispatch(disableMic());
        store.dispatch(disableCamera());

        // Give things a little time to finalize connecting before recovering Unity state
        const RECONNECT_UNITY_TIMEOUT = 5;
        this.reconnectTimeout = setTimeout(async () => {
          Object.keys(this.peerState).forEach((peerId) => {
            World.onUpdatePeerFloatingName(
              peerId,
              this.peerState[peerId].displayName,
            );
          });
          await this.sendState();
        }, RECONNECT_UNITY_TIMEOUT);

        showMessage({
          message: 'Reconnected',
          description: 'You can now use your microphone / camera',
          icon: 'auto',
          type: 'success',
          floating: true,
          duration: 3000,
          style: {width: 250},
        });

        clearTimeout(this.disconnectTimeout);
      } else {
        // Hide any messages for clean up in event room state is not `disconnected`
        hideMessage();
      }

      store.dispatch(stateActions.setRoomState(ROOM_STATE.connected));

      // Clean all the existing notifcations.
      store.dispatch(stateActions.removeAllNotifications());

      store.dispatch(
        requestActions.notify({
          text: 'You are in the room!',
          timeout: 3000,
        }),
      );

      for (const peer of peers) {
        store.dispatch(
          stateActions.addPeer({...peer, consumers: [], dataConsumers: []}),
        );
      }

      // When joining a meeting, set meeting to be valid for analytics if at least one peer exists
      // A valid meeting is defined as a meeting that has at least 2 participants
      if (peers && peers.length >= 1) {
        store.dispatch(setIsMeetingValid(true));
      }

      // Enable mic/webcam/chat.
      if (this._produce) {
        // Set our media capabilities.
        store.dispatch(
          stateActions.setMediaCapabilities({
            canSendMic: this._mediasoupDevice.canProduce('audio'),
            canSendWebcam: this._mediasoupDevice.canProduce('video'),
          }),
        );

        const {micStream, camStream} = store.getState().media;

        this.setMicStream(micStream);
        this.setWebcamStream(camStream);
        this.enableChatDataProducer();
      }

      // Listen for any changes from the redux store
      this.reduxSubscription = store.subscribe(async () => {
        this.onReduxStoreChange();
      });
      await this.onReduxStoreChange();

      // NOTE: For testing.
      if (window.SHOW_INFO) {
        const {me} = store.getState().communication;

        store.dispatch(stateActions.setRoomStatsPeerId(me.id));
      }
    } catch (error) {
      logger.error('_joinRoom() failed:%o', error);

      store.dispatch(
        stateActions.setConnectionFailure(
          '_joinRoom',
          `${error}`,
          'Failed to connect media',
        ),
      );

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Could not join the room: ${error}`,
        }),
      );

      this.close();
    }
  }

  async _pauseConsumer(consumer) {
    if (consumer.paused) {
      return;
    }

    try {
      await this._protoo.request('pauseConsumer', {consumerId: consumer.id});

      consumer.pause();

      store.dispatch(stateActions.setConsumerPaused(consumer.id, 'local'));
    } catch (error) {
      logger.error('_pauseConsumer() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error pausing Consumer: ${error}`,
        }),
      );
    }
  }

  async _resumeConsumer(consumer) {
    if (!consumer.paused) {
      return;
    }

    try {
      await this._protoo.request('resumeConsumer', {consumerId: consumer.id});

      consumer.resume();

      store.dispatch(stateActions.setConsumerResumed(consumer.id, 'local'));
    } catch (error) {
      logger.error('_resumeConsumer() | failed:%o', error);

      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: `Error resuming Consumer: ${error}`,
        }),
      );
    }
  }

  async sendWorldMessage(message) {
    if (!this._protoo.connected) {
      return new Promise((resolve) => resolve());
    }

    return this._protoo.notify('world', message).catch((error) => {
      console.log(`failed to send world message: ${error}`);
    });
  }

  async setAppearance(appearance) {
    this.localState.appearance = appearance;
    return this.sendState();
  }

  async setLocation(location) {
    this.localState.location = location;
    return this.sendState();
  }

  async sendState() {
    if (!this._protoo.connected) {
      return new Promise((resolve) => resolve());
    }

    return this._protoo.notify('peerState', this.localState).catch((error) => {
      console.log(`failed to send state message: ${error}`);
    });
  }

  async syncAllPeerState() {
    const peerIds = Object.keys(this.peerState);
    peerIds.forEach((peerId) => {
      this.syncPeerState(peerId);
    });
  }

  async syncPeerState(peerId, stateRef = null) {
    const state = stateRef || this.peerState[peerId];
    // only process the peer state in Unity if it isn't your own peer state. Note this currently only updates the avatars appearance
    // only process the state if the location of the peer is the same as the local user
    if (
      peerId !== this._displayName &&
      state.location === this.localState.location
    ) {
      World.onReceivePeerState(peerId, state);
      this.updateFloatingName(peerId);
    }
  }

  async updateFloatingName(peerId) {
    const {peers} = store.getState().communication;
    if (peers[peerId] && peerId !== this._displayName) {
      World.onUpdatePeerFloatingName(peerId, peers[peerId].displayName);
    }
  }

  async recallAllPeers() {
    const {peers} = store.getState().communication;
    const peerIds = Object.keys(peers);
    peerIds.forEach((peerId) => {
      this.recallPeer(peerId);
    });
  }

  async recallPeer(peerId) {
    const {location} = store.getState().world;
    return this._protoo
      .request('recallPeer', {peerId, location})
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(`Recall failed: ${error}`);
      });
  }

  async endMeetingForAll() {
    if (!this._protoo.connected) {
      return new Promise((resolve) => resolve());
    }

    return this._protoo.request('closeRoom').catch((error) => {
      logEvent(CLOSE_ROOM_FAILURE, {
        meeting_id: this.meetingId,
        error_name: error.name,
        error_message: error.message,
      });
      console.log(`failed to send closeRoom message: ${error}`);
    });
  }

  async muteRoomAudio(mute) {
    return this._protoo.request('muteRoomAudio', {mute}).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(`Mute Room Audio failed: ${error}`);
    });
  }

  async setRoomVoicePositionalMode(mode) {
    return this._protoo
      .request('setVoicePositionalMode', {mode})
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Voice Positional Mode change failed: ${error}`);
      });
  }

  async setRoomVideoPositionalMode(mode) {
    return this._protoo
      .request('setVideoPositionalMode', {mode})
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Video Positional Mode change failed: ${error}`);
      });
  }

  async muteRoomVideo(mute) {
    return this._protoo.request('muteRoomVideo', {mute}).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(`Mute Room Video failed: ${error}`);
    });
  }

  async muteRoomChat(mute) {
    return this._protoo.request('muteRoomChat', {mute}).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(`Mute Room Chat failed: ${error}`);
    });
  }

  async mutePeerAudio(peerId, mute) {
    return this._protoo
      .request('mutePeerAudio', {peerId, mute})
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Mute Peer Audio failed: ${error}`);
      });
  }

  async mutePeerVideo(peerId, mute) {
    return this._protoo
      .request('mutePeerVideo', {peerId, mute})
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Mute Peer Video failed: ${error}`);
      });
  }

  async mutePeerChat(peerId, mute) {
    return this._protoo
      .request('mutePeerChat', {peerId, mute})
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Mute Peer Chat failed: ${error}`);
      });
  }

  async onReduxStoreChange() {
    let state = store.getState();
    let {micStream, camStream} = state.media;
    if (!this.changingMic && micStream !== this.currentMicStream) {
      await this.setMicStream(micStream);
    }
    if (!this.changingWebcam && camStream !== this.currentWebcamStream) {
      await this.setWebcamStream(camStream);
    }
  }

  getProducer(producerId) {
    if (this._micProducer && this._micProducer.id === producerId) {
      return this._micProducer;
    }
    if (this._webcamProducer && this._webcamProducer.id === producerId) {
      return this._webcamProducer;
    }
    if (this._shareProducer && this._shareProducer.id === producerId) {
      return this._shareProducer;
    }
    if (this._chatDataProducer && this._chatDataProducer.id === producerId) {
      return this._chatDataProducer;
    }
    return undefined;
  }

  handleRemotePauseProducer(producerId) {
    if (this._micProducer && this._micProducer.id === producerId) {
      store.dispatch(disableMic());
    }
    if (this._webcamProducer && this._webcamProducer.id === producerId) {
      store.dispatch(disableCamera());
    }
    if (this._shareProducer && this._shareProducer.id === producerId) {
      store.dispatch(disableScreenShare());
    }
  }

  async setLocalRoomConfigs(configs) {
    const {locations, startingLocation, access, content} = configs;

    if (locations && typeof locations !== 'undefined') {
      store.dispatch(setLocations(locations));
    }

    if (
      startingLocation &&
      typeof startingLocation !== 'undefined' &&
      startingLocation !== ''
    ) {
      const state = store.getState();
      const {location, initialLoadComplete} = state.world;
      const {locations: currentLocations} = state.meeting.properties;
      const currentLocationAvailable = currentLocations.find(
        (loc) => location === loc.id,
      );
      if (
        !initialLoadComplete ||
        !currentLocationAvailable ||
        typeof currentLocationAvailable === 'undefined'
      ) {
        store.dispatch(setLocationAction(startingLocation));
      }
    }

    if (access && access.invite && access.invite.inviteRequestURL) {
      store.dispatch(setInviteURL(access.invite.inviteRequestURL));
    }

    store.dispatch(setViewboardContent(content));
  }

  async requestFileshareUpload(name, mimetype, size, hash) {
    try {
      return await this._protoo.request('uploadFileshareFile', {
        name,
        mimetype,
        size,
        hash,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`uploadFileshareFile failed: ${error}`);
      return error;
    }
  }

  async refreshRoomConfigs() {
    try {
      await this._protoo.request('refreshRoomConfigs');

      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`refreshRoomConfigs failed: ${error}`);
      return false;
    }
  }

  async requestAddInstanceContent(url) {
    try {
      await this._protoo.request('addInstanceContent', {
        url,
      });

      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`addInstanceContent failed: ${error}`);
      return false;
    }
  }

  getCommunicationServer() {
    return getHostName(this._protooUrl);
  }
}
