export const GO_TO_LANDING = 'GO_TO_LANDING';
export const GO_TO_MEETING = 'GO_TO_MEETING';
export const GO_TO_DEVICE_CONFIG = 'GO_TO_DEVICE_CONFIG';
export const GO_TO_AVATAR_PREVIEW = 'GO_TO_AVATAR_PREVIEW';

export const SET_DYNAMIC_LINK = 'SET_DYNAMIC_LINK';
export const USE_DYNAMIC_LINK = 'USE_DYNAMIC_LINK';

export const SET_REMOTE_CONFIG_LOADED = 'SET_REMOTE_CONFIG_LOADED';

export const SET_ALL_AVAILABLE_LEVELS = 'SET_ALL_AVAILABLE_LEVELS';
export const ADD_AVAILABLE_LEVEL = 'ADD_AVAILABLE_LEVEL';
export const UPDATE_AVAILABLE_LEVEL = 'UPDATE_AVAILABLE_LEVEL';
export const REMOVE_AVAILABLE_LEVEL = 'REMOVE_AVAILABLE_LEVEL';
export const SET_LEVEL_CACHE_DIRECTORY = 'SET_LEVEL_CACHE_DIRECTORY';
export const SET_COURSE_TOKEN_VALUES = 'SET_COURSE_TOKEN_VALUES';
export const SET_COURSE_PROGRESS = 'SET_COURSE_PROGRESS';

export const SET_COURSE_INFO_VALUES = 'SET_COURSE_INFO_VALUES'